import React, { memo, useEffect } from 'react';
import type { FC } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw, color } from '@wowmaking/ui/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import Animated, { FadeIn } from 'react-native-reanimated';

import * as COLORS from 'constants/colors';
import Header from 'components/header';
import { t } from 'localization';
import Text from 'components/text';
import { AppDispatch, RootState } from 'store';
import { getCardById } from 'store/tarot/selectors';
import Analytics from 'analytics';
import AstrologersPromo from 'components/banner/astrologers-promo';
import { ASTROLOGERS_PROMO_SIZES } from 'components/banner/astrologers-promo/constants';
import { ASTROLOGERS_PROMO_PLACES } from 'screens/advisors/constants';
import CachedImage from 'components/cached-image';
import { addActiveTodo } from 'store/astro-calendar/actions';
import { ASTRO_CALENDAR_NAVIGATE_TODO_ID, ASTRO_CALENDAR_TODO_TYPE } from 'screens/astro-calendar/constants';

import Background from '../components/background';
import { TAROT_LAYOUT_IDS } from '../constants';

const YesOrNo: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const cardId = useSelector((state: RootState) => state.tarot.selectedCards[TAROT_LAYOUT_IDS.YES_OR_NO][0]);
  const card = useSelector((state: RootState) => getCardById(state, cardId));

  const handleAstrologersPromoPress = () => {
    return {
      taroCard: card?.text?.name,
      message: `${card?.text?.overview}`,
    };
  };

  useEffect(() => {
    Analytics.track('Tarot_YesOrNoDescription_Open');

    /* Astro Calendar */
    dispatch(addActiveTodo({ type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE, contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.TAROT_READINGS }));
  }, []);

  return (
    <View style={styles.root}>
      <Background />
      <Header title={t('TAROT.CARD_LAYOUTS.YES_OR_NO.TITLE')} backIcon withBackground={false} />

      <ScrollView contentContainerStyle={styles.content} showsVerticalScrollIndicator={false} scrollEventThrottle={200}>
        <CachedImage style={styles.card} source={{ uri: card?.tarot_image?.image_path }} />

        <Animated.View entering={FadeIn.delay(200)}>
          <Text font={'Philosopher'} style={styles.title}>
            {card?.text?.name}
          </Text>
        </Animated.View>

        <Animated.View entering={FadeIn.delay(400)} style={styles.descWr}>
          <Text font={'Philosopher'} style={styles.descTitle}>
            {t('TAROT.CARD_LAYOUTS.YES_OR_NO.TITLE_ANSWER')}
          </Text>
          <Text style={styles.descSubtitle}>{card?.text?.yes_or_no}</Text>
        </Animated.View>

        <Animated.View entering={FadeIn.delay(600)} style={styles.promoWr}>
          <AstrologersPromo type={ASTROLOGERS_PROMO_SIZES.SMALL} place={ASTROLOGERS_PROMO_PLACES.TAROT} onPress={handleAstrologersPromoPress} />
        </Animated.View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: COLORS.DARK_GREEN,
  },
  content: {
    paddingHorizontal: paddingHorizontal(16),
    alignItems: 'center',
  },
  card: {
    width: sw(150),
    height: sw(275),
    marginTop: paddingVertical(45),
  },
  title: {
    fontWeight: '700',
    fontSize: fs(18),
    lineHeight: fs(20),
    color: COLORS.BEIGE,
    textAlign: 'center',
    marginTop: paddingVertical(10),
    marginBottom: paddingVertical(25),
  },
  descWr: {
    backgroundColor: COLORS.DARK_TEAL,
    width: '100%',
    paddingVertical: paddingVertical(20),
    paddingHorizontal: paddingHorizontal(10),
    marginBottom: paddingVertical(20),
    borderRadius: sw(15),
    alignItems: 'center',
  },
  descTitle: {
    fontWeight: '700',
    fontSize: fs(20),
    lineHeight: fs(20),
    color: COLORS.BEIGE,
    textAlign: 'center',
  },
  descSubtitle: {
    fontWeight: '500',
    fontSize: fs(16),
    lineHeight: fs(20),
    color: color(COLORS.BEIGE, 0.8),
    textAlign: 'center',
    marginTop: paddingVertical(5),
  },
  promoWr: {
    width: '100%',
    marginBottom: paddingVertical(20),
  },
});

export default memo(YesOrNo);
