import { Platform } from 'react-native';
import Mutator from '@magnus/react-native-mutator';

import Analytics from 'analytics';
import { DefaultParams } from 'store/remote-config/interfaces';

import { MAGNUS_TOKEN } from '../../constants/general';

import { normalize } from './normalizer';

const REMOTE_CONFIG_CACHE_TIME = 0;

class RemoteConfigService {
  init() {
    if (!MAGNUS_TOKEN?.length) {
      return;
    }

    const config = {
      token: MAGNUS_TOKEN,
      useBackendStorage: false,
    };

    if (Platform.OS === 'web') {
      config.useBackendStorage = true;
    }

    return Mutator.init(config);
  }

  async fetch(defaultValues: DefaultParams) {
    await Mutator.fetch(REMOTE_CONFIG_CACHE_TIME);

    if (Platform.OS === 'web' && Mutator.getSessionFirst()) {
      Analytics.track('mutator_session_first');
    }

    const snapshot = (await Mutator.activate()) as DefaultParams | null;
    const remoteConfig = normalize(snapshot, defaultValues);

    return remoteConfig;
  }
}

const RemoteConfig = new RemoteConfigService();

export default RemoteConfig;
