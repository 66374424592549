import React, { FC, memo } from 'react';
import { StyleSheet, TouchableOpacity, StyleProp, ImageStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

interface Props {
  value: number;
  icon: FC<SvgProps>;
  isDisabled: boolean;
  style?: StyleProp<ImageStyle>;
  onPress: (value: number) => void;
}

const Star: FC<Props> = ({ value, icon, isDisabled, style, onPress }) => {
  const Icon = icon;

  const handlePress = () => {
    if (isDisabled) {
      return;
    }
    onPress(value);
  };

  return (
    <TouchableOpacity style={[styles.button, style]} onPress={handlePress} activeOpacity={!isDisabled ? 0.5 : 1}>
      <Icon width={23} height={22} />
    </TouchableOpacity>
  );
};

export default memo(Star);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
  },
});
