import { getPlatform } from 'utils/pwa';

import api from '..';

import { Subscribe, PUSH_TYPES } from './interfaces';

const CREATE_PUSH = '/notifications/create-push';
const MESSENGERS = '/notifications/settings';
const SET_FCM_TOKEN = '/firebase-notifications/track-device';

export const subscribeOnEmailNewsletter = (params: Subscribe): Promise<any> => {
  return api.fetch(MESSENGERS, {
    method: 'POST',
    data: {
      ...params,
      whatsapp_subscribe: null,
    },
  });
};

export const createPush = (type: PUSH_TYPES = PUSH_TYPES.PALM_REPORT, time: number): Promise<any> => {
  if (!type || !time) {
    return Promise.reject('No required params given');
  }

  return api.fetch(CREATE_PUSH, {
    method: 'POST',
    data: {
      type,
      needSend: time,
    },
  });
};

export const setFCMToken = (token: string): Promise<any> => {
  if (!token) {
    return Promise.reject('No FCM token given');
  }

  const platform_type = getPlatform();
  return api.fetch(SET_FCM_TOKEN, {
    method: 'POST',
    data: {
      token,
      platform_type,
    },
  });
};
