import { TYPES_OF_ISSUE, VIEW_TYPES, REPORTS_PRODUCT_CODES } from 'constants/upsale';

export enum TYPES {
  SET_REPORT_VIEWED = 'UP_SALES/SET_REPORT_VIEWED',
  SET_REPORT_READY = 'UP_SALES/SET_REPORT_READY',
  SET_REPORTS = 'UP_SALES/SET_REPORTS',
}

export interface UserReport {
  id: string;
  title: string;
  isViewed: boolean;
  isGift: boolean;
  resourceType: string;
  productCode: keyof REPORTS_PRODUCT_CODES | string;
  image: string; // url
  link: string; // url
  viewType: VIEW_TYPES;
  typeOfIssue: TYPES_OF_ISSUE;
  readyTimer: null | number; // in seconds
  readyDate: null | Date;
}

export interface State {
  viewedReports: UserReport['productCode'][];
  userReports: UserReport[];
  availableReports: UserReport['productCode'][];
  readyReports: UserReport['productCode'][];
}

interface SetReportViewed {
  type: typeof TYPES.SET_REPORT_VIEWED;
  payload: UserReport['productCode'];
}

interface SetReportReady {
  type: typeof TYPES.SET_REPORT_READY;
  payload: UserReport['productCode'];
}

interface SetReports {
  type: typeof TYPES.SET_REPORTS;
  payload: {
    userReports: UserReport[];
    availableReports: string[];
  };
}

export type ActionTypes = SetReportViewed | SetReports | SetReportReady;
