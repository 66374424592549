import { createSelector } from 'reselect';

import { RootState } from 'store';
import { getPalmReport as getUpsalePalmReport } from 'store/upsails/selectors';
import { getDiffSecondsFromNow } from 'utils/date';

import { HabitDetail, HANDS, WellBeingAspect } from './types';

const getReadyTimers = (state: RootState) => state.palmReading.readyTimers;
const getPreviews = (state: RootState) => state.palmReading.previews;
const getLeftHand = (state: RootState) => state.palmReading.reports?.left;
const getRightHand = (state: RootState) => state.palmReading.reports?.right;
const getHand = (_: RootState, hand: HANDS) => hand;

export const getReportReadyDate = createSelector(
  [getReadyTimers, getUpsalePalmReport, getHand],
  (timers, report, hand): Date | number | undefined => {
    const timer = timers?.[hand];
    const seconds = report?.readyDate ? getDiffSecondsFromNow(report.readyDate) : 0;
    if (hand === HANDS.LEFT && seconds > 0 && report?.readyDate) {
      return report.readyDate;
    }

    if (timer && getDiffSecondsFromNow(timer) > 0) {
      return timer;
    }

    return undefined;
  },
);

export const getPreviewURI = createSelector([getPreviews, getHand], (previews, hand) => {
  return previews[hand]?.uri;
});

export const getLeftHandTextedData = createSelector([getLeftHand], leftHand => {
  if (!leftHand) {
    return {
      astrologersMessage: '',
    };
  }

  const { core, fate, head, heart, life } = leftHand.result;

  const prepareOverview = () => {
    const lineScores = Object.entries(core.lineScore);
    const overview = lineScores.reduce((acc, [key, value]) => {
      const suggestion = core.lineSuggestion[key];
      return `${acc} Line: ${key}, Score: ${value}, Suggestion: ${suggestion};`;
    }, 'Overview:');

    return overview;
  };

  const prepareHeartLine = () => {
    const params = `Line values: Curvature: ${heart.curvature}, Depth: ${heart.depth}, Length: ${heart.length};`;
    const headOverview = `Line overview: ${heart.overview};`;
    const approachToLove = `Approach to love: Title: ${heart.approachToLove?.title}, Text: ${heart.approachToLove?.text};`;
    const emotionalType = `Emotional type: Title: ${heart.emotionalType?.title}, Text: ${heart.approachToLove?.text};`;
    const idealPartner = `Ideal partner: Text: ${heart.idealPartner?.text}, Traits: ${heart.idealPartner?.traits.split(',').join(', ')};`;

    return `Heart line: ${params} ${headOverview} ${approachToLove} ${emotionalType} ${idealPartner}`;
  };

  const prepareLifeLine = () => {
    const params = `Line values: Curvature: ${life.curvature}, Depth: ${life.depth}, Length: ${life.length};`;
    const lifeOverview = `Line overview: ${life.overview};`;
    const lifeExpectancy = `Life expectancy: Age: ${life.lifeExpectancy}, Text: ${life.lifeExpectancyText};`;
    const addictions = Object.entries(life?.addictions || {})?.reduce(
      (acc, [key, value]) => `${acc} ${key}: Title: ${value.title}, Text: ${value.text}, Value: ${value.value};`,
      'Addictions:',
    );
    const deceases = Object.entries(life?.deceases || {})?.reduce(
      (acc, [key, value]) => `${acc} ${key}: Title: ${value.title}, Text: ${value.text}, Value: ${value.value};`,
      'Deceases:',
    );

    return `Heart line: ${params} ${lifeOverview} ${lifeExpectancy} ${addictions} ${deceases}`;
  };

  const prepareHeadLine = () => {
    const params = `Line values: Curvature: ${head.curvature}, Depth: ${head.depth}, Length: ${head.length};`;
    const headOverview = `Line overview: ${head.overview};`;
    const cognitiveIntelligence = head.cognitiveIntelligence?.reduce(
      (acc, value) => `${acc} Type: ${value.type}, Score: ${value.score}, Title: ${value.title}, Description: ${value.description};`,
      'Cognitive intelligence:',
    );
    const potentialChallenges = head.personalityType?.reduce(
      (acc, value) => `${acc} Title: ${value.title}, Text: ${value.text}, Score: ${value.score};`,
      'Potential challenges:',
    );
    const talents = head.talents?.reduce((acc, value) => `${acc} Title: ${value.title}, Text: ${value.text}, Score: ${value.score};`, 'Talents:');

    return `Head line: ${params} ${headOverview} ${cognitiveIntelligence} ${potentialChallenges} ${talents}`;
  };

  const prepareFateLine = () => {
    const params = `Line values: Curvature: ${fate.curvature}, Depth: ${fate.depth}, Length: ${fate.length};`;
    const sourceOfMotivation = `Source of motivation: Title: ${fate.sourceOfMotivation?.title}, Text: ${fate.sourceOfMotivation?.text};`;
    const topCareers = `Top careers: title: ${fate.topCareers?.title}, Text: ${fate.topCareers?.text}, Professions: ${fate.topCareers.professions.join(', ')};`;
    const fateOverview = `Line overview: ${fate.overview};`;
    const chanceForSuccess = fate.chanceOfSuccess.reduce(
      (acc, value) => `${acc} Type: ${value.type}, Value: ${value.value}, Title: ${value.title}, Text: ${value.text};`,
      'Chance for success:',
    );
    const potentialChallenges = fate.potentialChallenges?.reduce(
      (acc, value) => `${acc} Title: ${value.title}, Text: ${value.text};`,
      'Potential challenges:',
    );

    return `Fate line: ${params} ${fateOverview} ${chanceForSuccess} ${potentialChallenges} ${sourceOfMotivation} ${topCareers}`;
  };

  const overview = prepareOverview();
  const heartData = prepareHeartLine();
  const lifeData = prepareLifeLine();
  const headData = prepareHeadLine();
  const fateData = prepareFateLine();
  const astrologersMessage = `${overview}\n${heartData}\n${lifeData}\n${headData}\n${fateData}`;

  return {
    overview,
    heartData,
    lifeData,
    headData,
    fateData,
    astrologersMessage,
  };
});

export const getRightHandTextedData = createSelector([getRightHand], rightHand => {
  if (!rightHand) {
    return {
      astrologersMessage: '',
    };
  }

  const { core, fate, head, heart, life } = rightHand.result;

  const prepareOverview = () => {
    const lineScores = Object.entries(core.lineScore);
    const overview = lineScores.reduce((acc, [key, value]) => {
      const suggestion = core.lineSuggestion[key];
      return `${acc} Line: ${key}, Score: ${value}, Suggestion: ${suggestion};`;
    }, 'Overview:');

    return overview;
  };

  const prepareHeartLine = () => {
    const params = `Line values: Curvature: ${heart.curvature}, Depth: ${heart.depth}, Length: ${heart.length};`;
    const headOverview = `Line overview: ${heart.overview};`;
    const marriageAge = `Marriage age: ${heart.marriageAgeText};`;
    const parentalAge = `Parental age: ${heart.parentalAgeText};`;
    const emotionalIntelligence = heart.emotionalIntelligence?.reduce(
      (acc, value) => `${acc} Type: ${value.type}, Score: ${value.score}, Title: ${value.title}, Description: ${value.description};`,
      'Emotional intelligence:',
    );
    const relationshipNeeds = heart.relationshipNeeds?.reduce(
      (acc, value) => `${acc} Title: ${value.title}, Text: ${value.text};`,
      'Relationship needs:',
    );

    return `Heart line: ${params} ${headOverview} ${marriageAge} ${parentalAge} ${emotionalIntelligence} ${relationshipNeeds}`;
  };

  const prepareLifeLine = () => {
    const params = `Line values: Curvature: ${life.curvature}, Depth: ${life.depth}, Length: ${life.length};`;
    const lifeOverview = `Line overview: ${life.overview};`;
    const stressTimes = `Stress times: ${life.stressTimesText};`;
    const wellBeingState = Object.entries(life?.wellBeingState || {})?.reduce(
      (acc, [key, value]: [string, WellBeingAspect]) => `${acc} ${key}: Title: ${value.title}, Text: ${value.description}, Score: ${value.score};`,
      'Well being state:',
    );
    const habits = Object.entries(life?.habits || {})?.reduce((acc, [key, value]: [string, HabitDetail[]]) => {
      const habit = value.reduce((habitAcc, habitValue) => {
        return `${habitAcc} Title: ${habitValue.title}, Text: ${habitValue.text};`;
      }, `${key}:`);
      return `${acc} ${habit};`;
    }, 'Habits:');

    return `Heart line: ${params} ${lifeOverview} ${stressTimes} ${wellBeingState} ${habits}`;
  };

  const prepareHeadLine = () => {
    const params = `Line values: Curvature: ${head.curvature}, Depth: ${head.depth}, Length: ${head.length};`;
    const headOverview = `Line overview: ${head.overview};`;
    const decisionMaking = `Decision making: Title: ${head.decisionMaking?.title}, Text: ${head.decisionMaking?.text};`;
    const problemSolving = `Problem solving: Title: ${head.problemSolving?.title}, Text: ${head.problemSolving?.text};`;
    const values = head.values?.reduce((acc, value) => `${acc} Title: ${value.title}, Text: ${value.text};`, 'Values:');

    return `Head line: ${params} ${headOverview} ${decisionMaking} ${problemSolving} ${values}`;
  };

  const prepareFateLine = () => {
    const params = `Line values: Curvature: ${fate.curvature}, Depth: ${fate.depth}, Length: ${fate.length};`;
    const fateOverview = `Line overview: ${fate.overview};`;
    const potentialChallenges = fate.potentialChallenges?.reduce(
      (acc, value) => `${acc} Title: ${value.title}, Text: ${value.text};`,
      'Potential challenges:',
    );
    const lifePath = Object.entries(fate?.lifePath || {})?.reduce((acc, [key, value]) => {
      const year = value.reduce((yearAcc, yearValue) => {
        return `${yearAcc} Event: ${yearValue.title}, Percent: ${yearValue.value}%;`;
      }, `${key}:`);
      return `${acc} ${year};`;
    }, 'Life Path:');
    const careerPath = Object.entries(fate?.careerPath || {})?.reduce((acc, [key, value]) => {
      const year = value.reduce((yearAcc, yearValue) => {
        return `${yearAcc} Event: ${yearValue.title}, Percent: ${yearValue.value}%;`;
      }, `${key}:`);
      return `${acc} ${year};`;
    }, 'Career Path:');

    return `Fate line: ${params} ${fateOverview} ${potentialChallenges} ${careerPath} ${lifePath}`;
  };

  const overview = prepareOverview();
  const heartData = prepareHeartLine();
  const lifeData = prepareLifeLine();
  const headData = prepareHeadLine();
  const fateData = prepareFateLine();
  const astrologersMessage = `${overview}\n${heartData}\n${lifeData}\n${headData}\n${fateData}`;

  return {
    overview,
    heartData,
    lifeData,
    headData,
    fateData,
    astrologersMessage,
  };
});
