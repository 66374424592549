import { Product, Transaction } from '@wowmaking/react-native-billing';

import { WebOneTimePurchase, WebPurchase, WebSubscription, WebProduct, PaymentMethods } from 'api/purchases/interfaces';

import { PROFILE_TYPES } from '../profile/types';

export enum BILLING_TYPES {
  SET_PENDING = 'BILLING/SET_PENDING',
  SET_WEB_TRANSACTIONS = 'BILLING/SET_WEB_TRANSACTIONS',
  SET_WEB_ONE_TIME_PURCHASES = 'BILLING/SET_WEB_ONE_TIME_PURCHASES',
  SET_WEB_ALL_SUBSCRIPTIONS = 'BILLING/SET_WEB_ALL_SUBSCRIPTIONS',
  SET_WEB_PRODUCTS = 'BILLING/SET_WEB_PRODUCTS',
  SET_PURCHASED = 'BILLING/SET_PURCHASED',
  SET_PURCHASE_DATE = 'BILLING/SET_PURCHASE_DATE',
  SET_TRANSACTIONS = 'BILLING/SET_TRANSACTIONS',
  SET_PRODUCTS_LOAD_SUCCESS = 'BILLING/SET_PRODUCTS_LOAD_SUCCESS',
  SET_PRODUCTS = 'BILLING/SET_PRODUCTS',
  SET_FAMILY_PRODUCT_SUBSCRIBED = 'BILLING/SET_FAMILY_PRODUCT_SUBSCRIBED',
  SET_FAKE_PURCHASE_STATUS = 'BILLING/SET_FAKE_PURCHASE_STATUS',
  SET_WEB_SUBSCRIPTION_SHOWED = 'BILLING/SET_WEB_SUBSCRIPTION_SHOWED',
  SET_WEB_PAYMENT_METHOD = 'BILLING/SET_WEB_PAYMENT_METHOD',
  SET_WEB_REDEMPTION_SUBSCRIPTION = 'BILLING/SET_WEB_REDEMPTION_SUBSCRIPTION',
}

export interface State {
  webTransactions: WebPurchase[];
  webAllSubscriptions: WebSubscription[];
  webOneTimePurchases: WebOneTimePurchase[];
  webProducts: WebProduct[];
  pending: boolean;
  productsLoadSuccess: boolean;
  purchaseDate: number;
  purchased: boolean;
  transactions: Transaction[];
  products: Product[];
  isFamilyProductSubscribed: boolean;
  isFakePurchaseActive: boolean;
  isWebSubscriptionShowed: boolean;
  redemptionSubscription: WebSubscription | null;
  webPaymentMethod: PaymentMethods | '';
}

interface SetWebTransactions {
  type: typeof BILLING_TYPES.SET_WEB_TRANSACTIONS;
  payload: WebPurchase[];
}

interface SetWebOneTimePurchases {
  type: typeof BILLING_TYPES.SET_WEB_ONE_TIME_PURCHASES;
  payload: WebOneTimePurchase[];
}

interface SetWebProducts {
  type: typeof BILLING_TYPES.SET_WEB_PRODUCTS;
  payload: WebProduct[];
}

interface SetWebAllSubscriptions {
  type: typeof BILLING_TYPES.SET_WEB_ALL_SUBSCRIPTIONS;
  payload: WebSubscription[];
}

interface SetPurchaseDate {
  type: typeof BILLING_TYPES.SET_PURCHASE_DATE;
  payload: number;
}

interface SetPending {
  type: typeof BILLING_TYPES.SET_PENDING;
  payload: boolean;
}

interface SetProductsLoadSuccess {
  type: typeof BILLING_TYPES.SET_PRODUCTS_LOAD_SUCCESS;
  payload: boolean;
}

interface SetFamilyProductSubscribed {
  type: typeof BILLING_TYPES.SET_FAMILY_PRODUCT_SUBSCRIBED;
  payload: boolean;
}

interface SetPurchase {
  type: typeof BILLING_TYPES.SET_PURCHASED;
  payload: boolean;
}

interface SetTransactions {
  type: typeof BILLING_TYPES.SET_TRANSACTIONS;
  payload: Transaction[];
}

interface SetProducts {
  type: typeof BILLING_TYPES.SET_PRODUCTS;
  payload: Product[];
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

interface SetFakePurchaseStatus {
  type: typeof BILLING_TYPES.SET_FAKE_PURCHASE_STATUS;
  payload: boolean;
}

interface SetWebSubscriptionShowed {
  type: typeof BILLING_TYPES.SET_WEB_SUBSCRIPTION_SHOWED;
}

interface SetWebPaymentMethod {
  type: typeof BILLING_TYPES.SET_WEB_PAYMENT_METHOD;
  payload: PaymentMethods;
}

interface SetRedemptionSubscription {
  type: typeof BILLING_TYPES.SET_WEB_REDEMPTION_SUBSCRIPTION;
  payload: WebSubscription;
}

export type ActionType =
  | SetWebTransactions
  | SetWebAllSubscriptions
  | SetWebOneTimePurchases
  | SetWebProducts
  | SetPurchaseDate
  | SetPending
  | SetProductsLoadSuccess
  | SetPurchase
  | SetTransactions
  | SetProducts
  | ResetUserData
  | SetFakePurchaseStatus
  | SetFamilyProductSubscribed
  | SetWebPaymentMethod
  | SetWebSubscriptionShowed
  | SetRedemptionSubscription;
