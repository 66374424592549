import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import RemoteConfig from 'modules/remote-config';
import { AppDispatch, AppGetState } from 'store';

import { SET_ONBOARDING_FLOW, SET_REMOTE_CONFIG, FETCH_REMOTE_CONFIG_FAIL } from './types';

export const setOnboardingFlow = createAction(SET_ONBOARDING_FLOW);
export const setRemoteConfig = createAction(SET_REMOTE_CONFIG);
export const handleFetchRemoteConfigError = createAction(FETCH_REMOTE_CONFIG_FAIL);

export function initRemoteConfig() {
  return async (dispatch: AppDispatch) => {
    Analytics.track('remote_config_fetch_start');
    RemoteConfig.init();
    const remoteConfig = await dispatch(fetchRemoteConfig());

    Analytics.setUserProperty('promoOffersEnabled', !!remoteConfig?.promoOffers?.enabled);

    return remoteConfig;
  };
}

function fetchRemoteConfig() {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const defaultParams = getState().remoteConfig.defaultParams;

    try {
      const remoteConfig = await RemoteConfig.fetch(defaultParams);
      dispatch(setRemoteConfig(remoteConfig));
      Analytics.track('remote_config_fetch_success');
      return remoteConfig;
    } catch (err: any) {
      dispatch(handleFetchRemoteConfigError(true));
      const message = String(err?.message || err);
      console.log('> [REMOTE CONFIG FETCH ERROR]: ', message);
      Analytics.track('remote_config_fetch_error', { message });
      return defaultParams;
    }
  };
}
