import { useEffect, useCallback } from 'react';

import Analytics from 'analytics';
import { useAppSelector, useAppDispatch } from 'store';
import { setReportReady } from 'store/upsails/actions';
import { UserReport } from 'store/upsails/types';
import { useIsForeground } from 'hooks/use-is-foreground';
import { getDiffSecondsFromNow } from 'utils/date';

const useReportsTimersListener = () => {
  const isForeground = useIsForeground();
  const dispatch = useAppDispatch();
  const reports = useAppSelector(state => state.upsails.userReports);
  const readyReports = useAppSelector(state => state.upsails.readyReports);

  const reportReady = useCallback((report: UserReport) => {
    Analytics.track('Personal_Report_Ready', { report: report.productCode });
    dispatch(setReportReady(report.productCode));
  }, []);

  useEffect(() => {
    if (!isForeground) {
      return;
    }

    const timeouts: ReturnType<typeof setTimeout>[] = [];

    reports.forEach(report => {
      if (report.readyDate && !readyReports.includes(report.productCode)) {
        const remainingSeconds = getDiffSecondsFromNow(report.readyDate);
        if (remainingSeconds > 0) {
          const timeout = setTimeout(() => {
            reportReady(report);
          }, remainingSeconds * 1000);
          timeouts.push(timeout);
        } else {
          reportReady(report);
        }
      }
    });

    return () => {
      timeouts.forEach(clearTimeout);
    };
  }, [isForeground, reports, reportReady, readyReports]);

  return null;
};

export default useReportsTimersListener;
