import React, { FC, memo } from 'react';
import { Text as RNText, StyleSheet, StyleProp, TextStyle, TextProps, Platform } from 'react-native';
import { isArray } from 'lodash';

import { Philosopher, Philosopher_Bold, OpenSans, OpenSans_Semibold, OpenSans_Bold, FONT_TYPES } from '../constants/fonts';

interface Props extends TextProps {
  font?: FONT_TYPES;
}

const Text: FC<Props> = React.forwardRef<RNText, Props>(({ font = 'OpenSans', style, ...others }, ref) => {
  const isWeb = Platform.OS === 'web';

  const createStyles = () => {
    const fontStyles = isArray(style) ? StyleSheet.flatten(style) : style;
    const fontStyle = fontStyles as TextStyle;
    const fontWeightStyle = fontStyle?.fontWeight?.toString();
    const fontFamily = fontStyle?.fontFamily ? fontStyle?.fontFamily : font;
    const isPhilosopher = fontFamily === 'Philosopher';
    const fontWeight = fontWeightStyle ? fontWeightStyle : 'normal';
    const fontOptions: StyleProp<TextStyle> = {};

    if (['700', '800', '900', 'bold'].includes(fontWeight)) {
      fontOptions.fontFamily = isPhilosopher ? Philosopher_Bold : OpenSans_Bold;
      fontOptions.fontWeight = !isPhilosopher || isWeb ? '700' : undefined;
    }

    if (['500', '600'].includes(fontWeight)) {
      fontOptions.fontFamily = isPhilosopher ? Philosopher_Bold : OpenSans_Semibold;
      fontOptions.fontWeight = !isPhilosopher || isWeb ? '600' : undefined;
    }

    if (['400', 'normal'].includes(fontWeight)) {
      fontOptions.fontFamily = isPhilosopher ? Philosopher : OpenSans;
      fontOptions.fontWeight = !isPhilosopher || isWeb ? '400' : undefined;
    }

    if (['100', '200', '300'].includes(fontWeight)) {
      fontOptions.fontFamily = isPhilosopher ? Philosopher : 'OpenSans-Light';
    }

    const styles = StyleSheet.flatten([style, fontOptions]);

    return styles;
  };

  return <RNText ref={ref} allowFontScaling={false} style={createStyles()} {...others} />;
});

export default memo(Text);
