import api from 'api';

import { GET_MESSAGES, MessagesPage, Astrologist } from './constants';

const getMessages = async (id: Astrologist['astrologer_id'], receiveTarot: boolean = true, page: number = 0): Promise<MessagesPage> => {
  try {
    const response = await api.fetch(`${GET_MESSAGES}?astrologer_id=${id}&page=${page}&receiveTarot=${receiveTarot}`, {
      method: 'GET',
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] getHistory', error);
    return {
      messages: [],
      isNextPage: false,
      pageCount: 0,
      messagesCount: 0,
    };
  }
};

export default getMessages;
