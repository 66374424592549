import { Platform } from 'react-native';

import api from 'api';
import { ProfileData } from 'api/profile/interfaces';

const CREATE_CUSTOM_TOKEN_URL = '/auth/firebase/create';
const AUTH = '/auth';
const AUTH_OTC = '/auth/otc/auth';
const AUTH_FIREBASE = '/auth/firebase/auth';

export const createCustomToken = async (idfm: string) => {
  const response = await api.fetch(CREATE_CUSTOM_TOKEN_URL, {
    method: 'POST',
    data: {
      uid: idfm,
    },
  });

  return response?.custom_token ?? null;
};

export const auth = (device_id_new: string): Promise<ProfileData> => {
  return api.fetch(AUTH, {
    method: 'POST',
    data: {
      device_id_new,
      increment_cross_session: 1,
    },
  });
};

export const authOneTimeCode = (token: string): Promise<ProfileData> => {
  return api.fetch(AUTH_OTC, {
    method: 'POST',
    data: {
      token,
      increment_cross_session: 1,
    },
  });
};

export const authByFirebaseIdToken = (token?: string): Promise<ProfileData> => {
  if (!token) {
    return Promise.reject('Token is required');
  }
  return api.fetch(AUTH_FIREBASE, {
    method: 'POST',
    data: {
      token,
      increment_cross_session: 1,
    },
  });
};

export const requestOTP = (email: string): Promise<[]> => {
  return api.fetch(AUTH + '/otp-requests', {
    method: 'POST',
    data: {
      email,
      payment_system_project: Platform.OS === 'web' ? process.env.REACT_APP_PAYMENT_SYSTEM_PROJECT : undefined,
    },
  });
};

export const authWithOTP = (code: string, email: string): Promise<ProfileData> => {
  return api.fetch(AUTH + '/otp-requests/auth', {
    method: 'POST',
    data: {
      code,
      email,
    },
  });
};

export const resetProfilePassword = (email: string): Promise<ProfileData> => {
  return api.fetch(AUTH + '/reset-password', {
    method: 'POST',
    data: {
      platform: Platform.OS,
      email,
    },
  });
};
