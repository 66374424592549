import React, { FC, useCallback, useState, useEffect } from 'react';
import { StyleSheet, Image, View } from 'react-native';
import Modal from 'react-native-modal';
import Animated, { useDerivedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { paddingHorizontal, paddingVertical, sw, fs, BOTTOM_OFFSET, color } from '@wowmaking/ui/src/utils';

import Analytics from 'analytics';
import { t2 } from 'localization';
import { useAppDispatch, useAppSelector } from 'store';
import { rate, ratedHandle, sendRate } from 'store/rate-us/actions';
import { closeCurrentModal } from 'store/modals/actions';
import { addActiveTodo } from 'store/astro-calendar/actions';
import { FEATURES } from 'store/rate-us/types';
import { navigateToSupport } from 'store/navigation/actions';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { ASTRO_CALENDAR_TODO_TYPE } from 'screens/astro-calendar/constants';
import Text from 'components/text';
import Button from 'components/button';
import CloseButtonWithTimeout from 'components/close-button-with-timeout';
import useModalIsVisible from 'hooks/use-modal-is-visibly';

import ICON_CROSS from '../images/beige-cross.png';
import STARS_IMG from '../images/stars.png';

import Star from './components/star';
import StarActive from './images/active-star.svg';
import StarInactive from './images/inactive-star.svg';

const PROBLEM_WRAP_BTN_HEIGHT = sw(50) + paddingVertical(20);

interface Props {}

const RateUsModal: FC<Props> = () => {
  const [rating, setRating] = useState(-1);
  const dispatch = useAppDispatch();
  const isVisible = useModalIsVisible(MODALS.RATE_US);
  const titleText = useAppSelector(state => state.remoteConfig.remoteConfigParams?.rateUs.titleText);
  const minRate = useAppSelector(state => state.remoteConfig.remoteConfigParams?.rateUs.minRate);
  const modalParams = useAppSelector(state => state.modals.params);
  const isNegative = rating > 0 && rating < minRate;

  const height = useDerivedValue(() => (isNegative ? PROBLEM_WRAP_BTN_HEIGHT : 0), [isNegative]);
  const opacity = useDerivedValue(() => (isNegative ? 1 : 0), [isNegative]);

  useEffect(() => {
    if (isVisible) {
      Analytics.track('RateUs_ShowCustom', { feature: modalParams?.feature, type: 'old' });
    }
  }, [isVisible]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      height: withTiming(height.value),
      opacity: withTiming(opacity.value, { duration: 500 }),
    };
  });

  const handleModalClose = () => dispatch(closeCurrentModal());

  const handleStarPress = useCallback((value: number) => {
    setRating(value);
  }, []);

  const handleCancelPress = () => {
    handleModalClose();
  };

  const handleSubmitPress = () => {
    handleModalClose();
    sendRate(rating);
    dispatch(rate(rating, modalParams?.feature));
    dispatch(ratedHandle(rating));

    if (modalParams?.feature === FEATURES.ASTRO_CALENDAR) {
      dispatch(addActiveTodo({ type: ASTRO_CALENDAR_TODO_TYPE.RATE_US }));
    }
  };

  const handleReportAProblemPress = () => {
    handleModalClose();
    Analytics.track('RateUs_ReportProblem_Click');
    dispatch(navigateToSupport(false));
    dispatch(rate(rating, modalParams?.feature));
  };

  const defaultTitle = titleText || t2('RATE_US.TITLE');
  const ratedTitle = isNegative ? t2('RATE_US.WRONG_TITLE') : t2('RATE_US.THANKS_TITLE');
  const title = rating < 0 ? defaultTitle : ratedTitle;

  const ActiveStar = StarActive;
  const InactiveStar = StarInactive;

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <View style={styles.header}>
          <CloseButtonWithTimeout timeout={0} onPress={handleCancelPress} source={ICON_CROSS} style={styles.cross} />

          <View style={styles.top} pointerEvents="none">
            <Image style={styles.image} source={STARS_IMG} resizeMode="contain" />
          </View>

          <View style={styles.headerContainer}>
            <Text font="Philosopher" style={styles.title}>
              {title}
            </Text>

            {isNegative && <Text style={styles.subTitle}>{t2('RATE_US.WRONG_SUBTITLE')}</Text>}
          </View>
        </View>

        <View style={styles.contentContainer}>
          <View style={styles.starsList}>
            {[1, 2, 3, 4, 5].map(value => {
              return <Star key={value} icon={rating >= value ? ActiveStar : InactiveStar} value={value} onPress={handleStarPress} />;
            })}
          </View>
        </View>

        <View style={styles.footer}>
          <Animated.View style={[styles.problemBtnWrap, animatedStyle]}>
            <Button title={t2('RATE_US.REPORT_PROBLEM')} isTransparent style={styles.problemBtn} onPress={handleReportAProblemPress} />
          </Animated.View>
          <Button title={t2('RATE_US.BUTTON')} disabled={rating < 0} style={styles.btn} onPress={handleSubmitPress} />
        </View>
      </View>
    </Modal>
  );
};

export default RateUsModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    height: 'auto',
    flexGrow: 0,
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
  },
  cross: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingVertical: 0,
    paddingRight: paddingHorizontal(20),
    zIndex: 1,
  },
  header: {
    marginTop: paddingVertical(20),
    width: '100%',
  },
  headerContainer: {
    width: '100%',
    alignSelf: 'center',
    maxWidth: sw(310),
    paddingHorizontal: paddingHorizontal(20),
  },
  top: {
    width: '100%',
    height: 71,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    position: 'absolute',
    alignSelf: 'center',
    width: '100%',
  },
  contentContainer: {
    paddingHorizontal: paddingHorizontal(20),
    alignItems: 'center',
  },
  title: {
    color: COLORS.BEIGE_2,
    marginTop: -paddingVertical(30),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(33),
    textAlign: 'center',
  },
  subTitle: {
    fontSize: fs(16),
    fontWeight: '400',
    lineHeight: fs(23),
    textAlign: 'center',
    marginTop: paddingVertical(10),
    color: color(COLORS.BEIGE_2, 0.8),
  },
  footer: {
    paddingHorizontal: paddingHorizontal(20),
    alignItems: 'center',
  },
  problemBtnWrap: {
    width: '100%',
    alignSelf: 'center',
    maxWidth: sw(310),
    overflow: 'hidden',
  },
  problemBtn: {
    alignSelf: 'center',
    maxWidth: sw(310),
    borderColor: COLORS.AQUA,
    backgroundColor: COLORS.LIGHT_POWDER_BLUE,
    borderWidth: 1.5,
    borderRadius: sw(50),
  },
  btn: {
    width: '100%',
    alignSelf: 'center',
    maxWidth: sw(310),
  },
  starsList: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: paddingVertical(40),
    gap: 16,
  },
});
