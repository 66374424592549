import type { FC } from 'react';

import useLoadUserHoroscopesV2 from './use-load-user-horoscopes-v2';
import useLoadTodaysFeatures from './use-load-todays-features';
import useTrackAstroCalendarTodos from './use-track-astro-calendar-todos';
import useUpdateAppContent from './use-update-app-content';
import useReportsTimersListener from './use-reports-timers-listener';

const StaticHooksRunner: FC = () => {
  useLoadUserHoroscopesV2();
  useLoadTodaysFeatures();
  useTrackAstroCalendarTodos();
  useUpdateAppContent();
  useReportsTimersListener();

  return null;
};

export default StaticHooksRunner;
