import { OTHERS_PAYMENT_SCREENS, PERIOD_TYPES, CURRENCIES } from 'modules/payments/constants';
import type { Currency, AllPaymentMethods } from 'modules/payments/interfaces';
import { PRODUCT_TYPES } from 'modules/payments/constants/product';
import { SOFT_CURRENCY_TYPES } from 'components/advisors/soft-currency/constants';
import type { AstrologerUserData } from 'store/astrologers/monetization/types';

export enum PACK_SCREENS_IDS {
  PACKS = 'PACKS',
  SPECIAL_OFFER = 'SPECIAL_OFFER',
}

export enum PACK_TRIGGER_IDS {
  PACKS = 'asb_packs',
  OFFER_1 = 'asb_packs_offer1',
}

export enum TOP_TABS_IDS {
  MINUTES = 'minutes',
  UNLIM = 'unlim',
}

export const TOP_TABS: TOP_TABS_IDS[] = [TOP_TABS_IDS.MINUTES, TOP_TABS_IDS.UNLIM];

export interface AdvisorSoftCurrencyConfig {
  enabled: boolean;
  type: SOFT_CURRENCY_TYPES;
  rate: number;
}

export interface AstrologerPacksFlows {
  noPurchased: PACK_SCREENS_IDS[];
  purchased: PACK_SCREENS_IDS[];
}

export type AstrologerPackRoutes = {
  [key in PACK_SCREENS_IDS]: string;
};

export enum ASTROLOGERS_TRIGGERS_TYPES {
  BALANCE = 'balance',
  CHAT_BTN = 'chat_btn',
  CHAT_TIME_UP = 'chat_time_up',
  CHAT_NOTIFICATION = 'chat_notification',
  END_CHAT = 'end_chat',
  ADVISORS_ONBOARDING = 'advisors_onboarding',
  BLUR_MESSAGE = 'blur_message',
  SUGGESTS = 'suggests',
  SUGGESTS_POPUP = 'suggests_popup',
  AUTO_REFILL = 'auto_refill',
  CONNECTION_OPEN = 'connection_open',
  CHAT_BANNER = 'chat_banner',
}

export type AstrologersMonetizationTriggers = Exclude<
  ASTROLOGERS_TRIGGERS_TYPES,
  | ASTROLOGERS_TRIGGERS_TYPES.CHAT_BTN
  | ASTROLOGERS_TRIGGERS_TYPES.CHAT_NOTIFICATION
  | ASTROLOGERS_TRIGGERS_TYPES.ADVISORS_ONBOARDING
  | ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS
  | ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS_POPUP
>;

export type AstrologersMonetizationConfig = {
  [key in AstrologersMonetizationTriggers]?: boolean;
};

export enum ASTROLOGER_CONSULTATION_PRODUCTS {
  ASTROLOGER_CONSULTATION = 'astrologer_consultation',
  ASTROLOGER_CONSULTATION_60 = 'astrologer_consultation_60',
  ASTROLOGER_CONSULTATION_600 = 'astrologer_consultation_600',
  ASTROLOGER_CONSULTATION_180 = 'astrologer_consultation_1800',
  ASTROLOGER_CONSULTATION_3600 = 'astrologer_consultation_3600',
}

export const ASTROLOGER_PRODUCTS = {
  ...ASTROLOGER_CONSULTATION_PRODUCTS,
} as const;

export interface CurrencyConfig {
  id?: string;
  notActivePrice?: string;
  activePrice?: string;
  price?: number;
}

export enum RIGHT_TEXT_TYPES {
  GREEN = 'green',
}

export type RightTextColor = RIGHT_TEXT_TYPES | string | null;

export type Product = {
  title: string;
  notActivePrice: string;
  badgeText: string;
  rightText?: string;
  rightTextColor?: RightTextColor;
  rightSubText?: string;
} & Partial<Record<CURRENCIES, CurrencyConfig>>;

export interface Pack extends Product {
  saveAmount?: string;
  activePrice: string;
  currency: Currency;
  price: number;
  minutesAmount: number;
  freeMinsAmount: number;
}

export interface Subs extends Product {
  id: string;
}

export interface PackProduct extends Pack {
  id: string;
  productId: Pack['minutesAmount'] | string;
  pricePerMin: string;
  softCurrencyAmount: number;
  softCurrencyRate?: number;
  pricePerSoftCurrency: string;
  type: PRODUCT_TYPES.ONE_TIME;
}

export interface SubsProduct extends Subs {
  price: number;
  currency: Currency;
  activePrice: string;
  productId: string;
  period: PERIOD_TYPES | string;
  type: PRODUCT_TYPES.SUBSCRIPTION;
}

export enum PackType {
  ONE = '0',
  TWO = '2',
  SUBS = 'subs',
  SUBS_TABS = 'subs_tabs',
}

export enum ViewType {
  FREE_MINS = 'freeMins',
  SAVE = 'save',
}

interface AstrologerConfigBase {
  title: string;
  subtitle: string;
  btnText: string;
  footnoteText: string;
  withSoftCurrencyRate: boolean;
}

export interface AstrologerPackSpecialOffer extends AstrologerConfigBase {
  pack: Pack;
}

export interface AstrologerConsultationPacks extends AstrologerConfigBase {
  packType: PackType;
  withCloseIcon: boolean;
  defaultProductId: number | string;
  viewType: ViewType;
  packs: Pack[];
  subs: Subs;
  activeTabIndex: 0 | 1;
  reactivationEnabled: boolean;
}

export interface AstrologerPaymentProductActionEventParams {
  currency: Currency;
  amount: PackProduct['price'] | SubsProduct['price'];
  productCode: PackProduct['id'] | SubsProduct['id'];
  productType?: PRODUCT_TYPES;
}

export interface AstrologerPaymentErrorEventParams {
  message: string;
  code: string;
}

export interface AstrologerPaymentEventParams
  extends AstrologerUserData,
    Partial<AstrologerPaymentErrorEventParams>,
    Partial<AstrologerPaymentProductActionEventParams> {
  screen: PACK_SCREENS_IDS | OTHERS_PAYMENT_SCREENS.CHAT;
  packType?: PackType;
  method?: AllPaymentMethods;
  //for astrologersOnetimeMonetizationHandler only
  idfm?: string;
  isSubBenefitsEnabled?: boolean;
  source?: string;
  [key: string]: any;
}
