import * as COLORS from './colors';

export enum CHARTS {
  EMOTIONAL = 'EMOTIONAL',
  PHYSICAL = 'PHYSICAL',
  INTELLECTUAL = 'INTELLECTUAL',
}

export const EMOTIONAL = CHARTS.EMOTIONAL;
export const PHYSICAL = CHARTS.PHYSICAL;
export const INTELLECTUAL = CHARTS.INTELLECTUAL;
export const BIORHYTHM_TYPES = [CHARTS.PHYSICAL, CHARTS.EMOTIONAL, CHARTS.INTELLECTUAL];

export const PERIODS_DAYS: { [key in CHARTS]: number } = {
  [CHARTS.EMOTIONAL]: 28,
  [CHARTS.PHYSICAL]: 23,
  [CHARTS.INTELLECTUAL]: 33,
};

export const COLOR_MAP: { [key in CHARTS]: string } = {
  [CHARTS.PHYSICAL]: COLORS.FADED_ORANGE,
  [CHARTS.EMOTIONAL]: COLORS.AQUA,
  [CHARTS.INTELLECTUAL]: COLORS.LIGHT_MUSTARD,
};
