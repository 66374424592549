import { createAction } from 'redux-actions';
import { isEmpty, shuffle, isBoolean } from 'lodash';

import Analytics from 'analytics';
import { addPromocode, getAstrologersProfiles } from 'api/astrology-chat';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { getRandomTexts } from 'utils/strings';
import { t } from 'localization';
import { setUserParams } from 'store/profile/actions';
import { getAstrologersSuggests as getSuggests } from 'api/astrologers';
import { getWebData } from 'store/palm-reading-daily/actions';
import { selectLeftHandData } from 'store/palm-reading-daily/selectors';
import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components/const';
import { FIRST_SESSION_TEMPLATES } from 'screens/advisors/constants';
import { ADVISORS_CHAT, SUBSCRIPTIONS } from 'constants/routes';

import { countUnreadMessages, getLastMessages, restartChatAfterPurchase } from '../chat/actions';
import { getAvailableTime, initChatTimer, setAstrologistFreeTime } from '../time/actions';
import { initOnboarding as initAdvisorsOnboarding } from '../onboarding/actions';
import { getLastConnectedAstrologer } from '../selectors';
import { initAdvisorsCatalog } from '../catalog/actions';
import { addTriggerNotification } from '../notifications/actions';
import { initServices } from '../services/actions';

import { Question, QUESTION_TYPES, Questions, TYPES, QUESTIONS_CATEGORY_ALL } from './types';

export const setAdvisorPalmReadingData = createAction(TYPES.SET_ASTROLOGER_PALM_READING_DATA);
export const setAstrologistAction = createAction(TYPES.SET_ASTROLOGIST);
export const setQuestionsCategoryAction = createAction(TYPES.SET_QUESTIONS_CATEGORY);
export const setQuestionsAction = createAction(TYPES.SET_QUESTIONS);
export const setAstrologers = createAction(TYPES.SET_ASTROLOGERS);
export const setLastConnectionSessionNumber = createAction(TYPES.SET_LAST_CONNECTION_SESSION_NUMBER);
export const setLastConnectedAstrologer = createAction(TYPES.SET_LAST_CONNECTED_ASTROLOGER);
export const setPromocode = createAction(TYPES.SET_PROMOCODE);
const setAstrocartography = createAction(TYPES.SET_ASTROCARTOGRAPHY);
const setUnlimitedMode = createAction(TYPES.SET_UNLIMITED_MODE);
const setUnlimitedBenefitEnable = createAction(TYPES.SET_UNLIMITED_BENEFIT_VISIBLE);

const setLastConnectedAstrologerData = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const lastAstrologist = getLastConnectedAstrologer(state);
    const randomAiAstrologist = state.astrologers.core.astrologist;

    dispatch(setLastConnectedAstrologer(isEmpty(lastAstrologist) ? randomAiAstrologist : lastAstrologist));
  };
};

let inited = false;
export const initAstrologist = () => {
  return async (dispatch: AppDispatch) => {
    // to prevent double init with whereNavigateAfterOnboarding = 'chat'
    if (inited) {
      return false;
    }
    inited = true;

    const astrologers = await getAstrologersProfiles();

    if (!astrologers.length) {
      return false;
    }

    dispatch(setAstrologistFreeTime());

    const randomAstrologers = shuffle(astrologers);
    const activeAstrologers = randomAstrologers.filter(astrologer => astrologer.is_active);
    const randomAstrologist = activeAstrologers[Math.floor(Math.random() * activeAstrologers.length)];
    dispatch(setAstrologers(randomAstrologers));
    dispatch(setAstrologistAction(randomAstrologist));

    dispatch(initAdvisorsOnboarding());
    dispatch(initAdvisorsCatalog());

    await dispatch(initQuestions());

    await dispatch(initAstrologistPalmReading());
    dispatch(initAdvisorAstrocartography());

    await Promise.all([dispatch(getAvailableTime()), dispatch(getLastMessages())]);
    dispatch(initChatTimer());
    dispatch(countUnreadMessages());
    dispatch(setLastConnectedAstrologerData());
    dispatch(initServices());
  };
};

const initQuestions = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: {
          advisorsSuggests: { selectedCategory },
          palmDuringChat,
        },
      },
    } = getState();

    try {
      const suggests = await getSuggests();

      const preparedSuggestsByCategories: Questions = Object.keys(suggests).reduce(
        (acc: any, key: string) => {
          const item = suggests[key];

          const questions: Question[] = [];
          Object.keys(item?.questions)?.forEach(id => {
            const question = item?.questions[id];
            const type = !question.type ? QUESTION_TYPES.SUGGEST : (question.type as QUESTION_TYPES);
            if (!palmDuringChat?.suggest && type === QUESTION_TYPES.PALM_REQUEST_SUGGEST) {
              return false;
            }
            questions.push({ id, text: question.text, metadata: { type } });
          });

          const allQuestions = acc.all.questions.concat(questions);

          return {
            ...acc,
            [QUESTIONS_CATEGORY_ALL]: {
              ...acc[QUESTIONS_CATEGORY_ALL],
              questions: getRandomTexts(allQuestions, allQuestions.length),
            },
            [key]: {
              ...item,
              questions: getRandomTexts(questions, questions.length),
            },
          };
        },
        {
          [QUESTIONS_CATEGORY_ALL]: {
            id: 999,
            title: t('TODAY.ASTROLOGIST.QUESTIONS.ALL_SUGGEST'),
            slug: QUESTIONS_CATEGORY_ALL,
            priority: 999,
            questions: [],
          },
        },
      );

      const orderSuggestBySelectedCategory = () => {
        const allCategory = preparedSuggestsByCategories[QUESTIONS_CATEGORY_ALL];
        const priorityCat = preparedSuggestsByCategories[selectedCategory];

        const restCategories = Object.entries(preparedSuggestsByCategories)
          .filter(([key]) => key !== QUESTIONS_CATEGORY_ALL && key !== selectedCategory)
          .sort(([, a], [, b]) => a.priority - b.priority);

        return {
          [QUESTIONS_CATEGORY_ALL]: allCategory,
          [selectedCategory]: priorityCat,
          ...Object.fromEntries(restCategories),
        };
      };

      const preparedSuggests = selectedCategory ? orderSuggestBySelectedCategory() : preparedSuggestsByCategories;

      // filter: only unique
      preparedSuggests.all.questions = preparedSuggests.all?.questions?.filter((value, index, self) => {
        return self.findIndex(v => v.text === value.text) === index;
      });

      dispatch(setQuestionsAction({ questions: preparedSuggests, chatQuestions: preparedSuggests }));

      return suggests;
    } catch (error) {
      console.log('[ERROR GET ASTROLOGER SUGGESTS]');
      return false;
    }
  };
};

export const initAstrologistPalmReading = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { remoteConfigParams },
      profile: {
        profileData: { userParams },
      },
    } = getState();
    const firstSessionTemplate = remoteConfigParams?.useFirstSessionTemplate || '';
    const isAdvisorPalmReadingFirstSessionEnabled = firstSessionTemplate.toLowerCase() === FIRST_SESSION_TEMPLATES.PALM_READING;

    if (!isAdvisorPalmReadingFirstSessionEnabled || userParams?.is_advisor_palm_reading_completed) {
      return false;
    }

    await dispatch(getWebData());

    const state = getState();
    const {
      palmReadingDaily: { data },
    } = state;

    if (!data?.leftHandImageUrl) {
      return false;
    }

    const leftHandAdvisorData = selectLeftHandData(state);

    if (!leftHandAdvisorData?.astrologersMessage) {
      return false;
    }

    const advisorData = {
      image: data.leftHandImageUrl,
      hiddenMessage: leftHandAdvisorData.astrologersMessage,
    };

    dispatch(setAdvisorPalmReadingData(advisorData));
    dispatch(setUserParams({ is_advisor_palm_reading_completed: true }));
    return true;
  };
};

export const initAdvisorAstrocartography = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { remoteConfigParams },
      profile: {
        profileData: { userParams },
      },
    } = getState();
    const firstSessionTemplate = remoteConfigParams?.useFirstSessionTemplate || '';
    const isAstrocartographyEnabled = firstSessionTemplate.toLowerCase() === FIRST_SESSION_TEMPLATES.ASTROCARTOGRAPHY;

    if (!isAstrocartographyEnabled || userParams?.is_advisor_astrocartography_completed) {
      return false;
    }

    dispatch(setAstrocartography(true));
    dispatch(setUserParams({ is_advisor_astrocartography_completed: true }));
    return true;
  };
};

export const handleExternalTrigger = (triggerPromocode?: string) => {
  return async (dispatch: AppDispatch, getSate: AppGetState) => {
    const {
      astrologers: {
        core: { promocode: externalPromocode, isUnlimitedMode },
      },
      auth: { accessToken },
    } = getSate();

    const promocode = triggerPromocode?.length ? triggerPromocode : externalPromocode;

    if (!accessToken.length) {
      return false;
    }

    if (!promocode.length) {
      return false;
    }

    if (isUnlimitedMode) {
      return false;
    }

    Analytics.track('Advisor_External_Trigger_Start');

    try {
      const res = await addPromocode({ code: promocode, increaseFreeBalance: true });

      if (!res || !res.seconds) {
        Analytics.track('Advisor_External_Trigger_Fail');
        return false;
      }

      Analytics.track('Advisor_External_Trigger_Success', { ...res });

      Analytics.track('TopNotification_Show', {
        type: EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER,
        seconds: res.seconds,
        promocode,
      });
      dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER, seconds: res.seconds }));
      return true;
    } catch (error) {
      console.log('ERROR handleExternalTrigger', error);
      Analytics.track('Advisor_External_Trigger_Fail');
      return false;
    } finally {
      await dispatch(getAvailableTime());
      dispatch(setPromocode(''));
    }
  };
};

export const initUnlimitedMode = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { purchased },
      remoteConfig: {
        remoteConfigParams: { enableUnlimitedChat },
      },
      profile: {
        profileData: { userParams },
      },
      navigation: { currentRoute, previousRoute },
      astrologers: {
        chat: { currentAstrologerId },
      },
    } = getState();

    if (!purchased) {
      dispatch(cancelUnlimitedMode());
      return false;
    }

    if (purchased) {
      let enable = !!userParams?.is_chat_unlimited;
      const hasUserParam = isBoolean(userParams?.is_chat_unlimited);

      if (!hasUserParam) {
        dispatch(
          setUserParams({
            is_chat_unlimited: enableUnlimitedChat,
          }),
        );
        enable = enableUnlimitedChat;
      }

      dispatch(setUnlimitedBenefitEnable(enable));
      dispatch(setUnlimitedMode(enable));

      if (enable && !hasUserParam) {
        Analytics.track('Advisor_Unlimited_Mode');

        // First condition for web
        // The second one, for mobile, because this function is called before some sub-screen is closed.
        if (currentRoute === ADVISORS_CHAT || (SUBSCRIPTIONS.includes(currentRoute) && previousRoute === ADVISORS_CHAT)) {
          await dispatch(restartChatAfterPurchase(currentAstrologerId));
        }
      }
    }

    dispatch(getAvailableTime());
    dispatch(initChatTimer());
  };
};

export const cancelUnlimitedMode = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { purchased },
      profile: {
        profileData: { userParams },
      },
      remoteConfig: {
        remoteConfigParams: { enableUnlimitedChat },
      },
    } = getState();

    if (purchased) {
      return false;
    }

    dispatch(setUnlimitedMode(false));
    dispatch(setUnlimitedBenefitEnable(enableUnlimitedChat));

    if (isBoolean(userParams?.is_chat_unlimited)) {
      dispatch(
        setUserParams({
          is_chat_unlimited: null,
        }),
      );
    }

    dispatch(getAvailableTime());
  };
};
