import React, { useEffect } from 'react';
import type { FC, CSSProperties } from 'react';
import { Alert } from 'react-native';

import Analytics from 'analytics';
import { t } from 'localization';
import * as COLORS from 'constants/colors';

interface Props {
  id: string;
  src: string;
  style?: CSSProperties;
  onError?: () => void;
}

const Iframe: FC<Props> = ({ src, id, style = iframeStyles, onError }) => {
  useEffect(() => {
    const isUrlAvailable = async () => {
      try {
        const response = await fetch(src, { method: 'HEAD' });

        if (!response.ok) {
          const status = response?.status;
          Analytics.track('web_iframe_load_error', { id, src, status });

          if (typeof onError === 'function') {
            Alert.alert(t('COMPATIBILITY.ERROR.TITLE'), t('SOMETHING_WRONG'), [
              {
                text: t('RP.OK'),
                style: 'default',
                onPress: () => onError(),
              },
            ]);
          }
        }
      } catch (err) {
        console.log('> Network error occurred', err);
      }
    };

    if (src) {
      isUrlAvailable();
    }
  }, [src]);

  return <iframe title={id} id={id} style={style} src={src} />;
};

export default Iframe;

const iframeStyles: CSSProperties = {
  height: '100%',
  width: '100%',
  border: 'none',
  backgroundColor: COLORS.DARK_GREEN,
};
