import _ from 'lodash';
import Tts from 'react-native-tts';
import { createAction } from 'redux-actions';
import Messaging from 'messaging';
import moment from 'moment';
import dayjs from 'dayjs';
import { getHoroscopesTransits } from '@wowmaking/birthchart';
import type { TransitWithDuration, HoroscopesTransitsData } from '@wowmaking/birthchart';
import type { ZodiacSign } from '@wowmaking/birthchart';

import { HoroscopeContentV2, TagBack, TagFront } from 'interfaces/horoscope';
import { t } from 'localization';
import { getHoroscope, getAllSigns, getUserHoroscopeV2 } from 'api/horoscope';
import { FUTURE_HOROSCOPE_NOTIFICATION_ID, HOROSCOPE_NOTIFICATION_ID, NOTIFICATION_UNIQ_ID, PUSH_BEHAVIOR } from 'constants/messaging';
import Analytics from 'analytics';
import { PERIODS_IDS } from 'constants/periods';
import { TAG_TYPES } from 'constants/horoscope';
import { CURRENT_YEAR, START_YEAR } from 'constants/date-time-arrays';
import { HoroscopeTypes } from 'constants/horoscopes-names';
import { SIGNS_ZODIAC, ZODIAC_EMOJI_MAP } from 'constants/signs-icons';
import { formatTextWithoutTags } from 'utils/text-format-replace-tags';
import { capitalize } from 'utils/strings';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { UserHoroscopeV2Item } from 'api/horoscope/interfaces';

import { processDailyTipsPredictions, setTips } from '../daily-tips/actions';
import { selectZodiacSign, selectIndianSignNextFromUser } from '../profile/selectors';
import { getAstroInputs, initBirthChart } from '../birth-chart/actions';

import type { DoDontPredictions, UserHoroscopeV2Period } from './types';
import { TYPES, AllSigns } from './types';
import { MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD, HOROSCOPE_REQUEST_STATUSES } from './constants';
import { selectUserHoroscopeV2, selectHasDataToGenerateUserHoroscopeV2, selectPromptId } from './selectors';

const setHoroscope = createAction(TYPES.SET_HOROSCOPE);
const setErrorHoroscope = createAction(TYPES.SET_ERROR);
const setAllSigns = createAction(TYPES.SET_ALL_SIGNS);
const setPendingHoroscope = createAction(TYPES.SET_PENDING);
const setHoroscopeInfo = createAction(TYPES.SET_HOROSCOPE_INFO);
const setZodiacAnimationCount = createAction(TYPES.SET_ZODIAC_ANIMATION_COUNT);
const setTodayIndianSunHoroscopeForNextSign = createAction(TYPES.SET_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN);
const setPendingTodayIndianSunHoroscopeForNextSign = createAction(TYPES.SET_PENDING_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN);
const addUserHoroscopeV2Item = createAction(TYPES.ADD_USER_HOROSCOPE_V2_ITEM);
const setDoDontPredictions = createAction(TYPES.SET_DO_DONT_PREDICTIONS);
export const setWidgetReloadIsRequired = createAction(TYPES.SET_WIDGET_RELOAD_IS_REQUIRED);
export const resetUserHoroscope = createAction(TYPES.RESET_USER_HOROSCOPE);
export const resetUserHoroscopeV2 = createAction(TYPES.RESET_USER_HOROSCOPE_V2);
const setHoroscopesTransits = createAction<HoroscopesTransitsData>(TYPES.SET_HOROSCOPES_TRANSITS);

export const initAllSigns = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await getAllSigns();

      const keys = Object.keys(response) as unknown as HoroscopeTypes[];
      const obj: Partial<AllSigns> = {};
      keys.forEach((key: HoroscopeTypes) => {
        switch (key) {
          case HoroscopeTypes.chinese:
            obj[key] = response[key].map(el => ({
              name: el.name,
              date: createArrayYearsByReminder(el.reminder),
            }));
            break;
          case HoroscopeTypes.zodiac:
            obj[key] = SIGNS_ZODIAC.map(el => {
              return _.find(response[key], {
                name: el.toLocaleLowerCase(),
              });
            });
            break;
          default:
            obj[key] = response[key];
        }
      });

      dispatch(setAllSigns(obj));
    } catch (error) {
      console.log('[ERROR INIT ALL SIGNS]: ', error);
    }
  };
};

export const loadZodiacForUser = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const userSign = selectZodiacSign(state);
    if (userSign) {
      await dispatch(loadHoroscope(HoroscopeTypes.zodiac, userSign.toLowerCase() as unknown as ZodiacSign, true));
    }
  };
};

export const loadIndianSunHoroscopeByNextSignFromUserSign = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      horoscope: {
        todayIndianSunHoroscopeNextSign: { sign },
      },
    } = state;
    const nextSign = selectIndianSignNextFromUser(state)?.toLowerCase();

    try {
      if (nextSign !== sign) {
        dispatch(setPendingTodayIndianSunHoroscopeForNextSign(true));
        const data = await getHoroscope(HoroscopeTypes.indian_sun, nextSign as unknown as ZodiacSign);
        const { horoscope_info, horoscope } = data;

        if (horoscope_info.sign === nextSign) {
          const index = _.findIndex(horoscope, { time_type: PERIODS_IDS.TODAY.toLowerCase() }, 0);
          const todayHoroscope = horoscope?.[index];
          if (todayHoroscope) {
            dispatch(
              setTodayIndianSunHoroscopeForNextSign({
                horoscope: todayHoroscope,
                sign: nextSign,
              }),
            );
          }
        }
      }
      return true;
    } catch (error) {
      console.log('[ERROR LOAD INDIAN NEXT SIGN]', error);
      return false;
    } finally {
      dispatch(setPendingTodayIndianSunHoroscopeForNextSign(false));
    }
  };
};

export const initHoroscopePushes = () => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      horoscope: { userHoroscopes },
      billing: { purchased },
      remoteConfig: {
        remoteConfigParams: {
          pushConfig: { dailyHoroscope },
        },
      },
    } = state;
    const sign = selectZodiacSign(state);
    const isAvailableToShow =
      dailyHoroscope === PUSH_BEHAVIOR.ALL ||
      (purchased && dailyHoroscope === PUSH_BEHAVIOR.PAID) ||
      (!purchased && dailyHoroscope === PUSH_BEHAVIOR.FREE);

    if (isAvailableToShow) {
      Messaging.cancelLocalNotification(NOTIFICATION_UNIQ_ID.HOROSCOPE);
      Messaging.cancelLocalNotification(NOTIFICATION_UNIQ_ID.FUTURE_HOROSCOPE);

      const title = `${t('DAILY_HOROSCOPE')} ${ZODIAC_EMOJI_MAP[sign?.toLowerCase() || '']}`;

      const horoscopesData = userHoroscopes[HoroscopeTypes.zodiac];
      const horoscopes = horoscopesData.find(({ time_type }) => time_type === PERIODS_IDS.TOMORROW.toLowerCase());
      const body = formatTextWithoutTags(horoscopes?.text || '');

      const horoscopeTime = new Date();
      horoscopeTime.setDate(horoscopeTime.getDate() + 1);
      horoscopeTime.setHours(8, 0);

      Messaging.schedulePlannedNotificationInternal({
        time: moment(horoscopeTime).unix() * 1000,
        title,
        body,
        uniqId: NOTIFICATION_UNIQ_ID.HOROSCOPE,
        id: HOROSCOPE_NOTIFICATION_ID,
      });

      const futureHoroscopeTime = new Date();
      futureHoroscopeTime.setDate(futureHoroscopeTime.getDate() + 1);
      futureHoroscopeTime.setHours(8, 0);

      Messaging.scheduleRepeatedNotificationInternal({
        notifyTime: moment(futureHoroscopeTime).unix() * 1000,
        title: `${t('SEE_DAILY_HOROSCOPE')} ${sign ? ZODIAC_EMOJI_MAP[sign.toLowerCase()] : ''}`,
        body: t('TAP_DAILY_HOROSCOPE'),
        uniqId: NOTIFICATION_UNIQ_ID.FUTURE_HOROSCOPE,
        id: FUTURE_HOROSCOPE_NOTIFICATION_ID,
        interval: 'day',
      });
    }
  };
};

export const loadHoroscope = (horoscope_type: HoroscopeTypes, sign: ZodiacSign, isUser = false) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setHoroscopeInfo({ sign, horoscope_type }));
    dispatch(setPendingHoroscope());

    try {
      const data = await getHoroscope(horoscope_type, sign, true);
      const { horoscopeInfo } = getState().horoscope;
      const { horoscope_info } = data;

      if (horoscope_info.horoscope_type === horoscopeInfo.horoscope_type && horoscope_info.sign === horoscopeInfo.sign) {
        dispatch(setHoroscope({ type: horoscope_type, data, isUser }));
        dispatch(initHoroscopePushes());
      }
    } catch (err) {
      console.warn(err);
      dispatch(setErrorHoroscope());
    }
  };
};

function createArrayYearsByReminder(reminder: number) {
  const countsOfSings = 12,
    array: number[] = [];

  let startYear = START_YEAR;

  while (startYear % countsOfSings !== reminder) {
    startYear++;
  }

  while (startYear <= CURRENT_YEAR) {
    array.push(startYear);
    startYear += countsOfSings;
  }

  return array;
}

export const narrateHoroscopePrediction = (text = '') => {
  return () => {
    Tts.getInitStatus().then(() => {
      Tts.setDefaultLanguage('en-US');
      Analytics.track('horoscope_narrate');
      Tts.stop();
      Tts.speak(formatTextWithoutTags(text));
    });
  };
};

export const incrementZodiacAnimationCount = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      horoscope: { zodiacAnimationCount },
    } = getState();
    const nextZodiacAnimationCount = zodiacAnimationCount + 1;
    dispatch(setZodiacAnimationCount(nextZodiacAnimationCount));
  };
};

let userHoroscopeV2LoadPromise: { [key in UserHoroscopeV2Period]?: Promise<UserHoroscopeV2Item[]> } = {};

export const loadUserHoroscopeV2 = (period: UserHoroscopeV2Period): AppThunk<Promise<HOROSCOPE_REQUEST_STATUSES>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    if (selectUserHoroscopeV2(state, period) !== null) {
      return HOROSCOPE_REQUEST_STATUSES.DATA_ALREADY_LOADED;
    }

    const loadPromise = userHoroscopeV2LoadPromise[period];
    if (loadPromise !== undefined) {
      return loadPromise.then(response => (response?.length ? HOROSCOPE_REQUEST_STATUSES.SUCCESS : HOROSCOPE_REQUEST_STATUSES.REQUEST_ERROR));
    }

    if (!selectHasDataToGenerateUserHoroscopeV2(state)) {
      return HOROSCOPE_REQUEST_STATUSES.NOT_ENOUGH_DATA;
    }

    const {
      birthChart: { natalPlanets: planets, natalHouses: houses },
      remoteConfig: {
        remoteConfigParams: { todayContentTipsEnabled, todayContentDoDontEnabled },
      },
    } = state;

    const pid = selectPromptId(state, period);
    const requestPeriod = MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD[period];
    const extraContentEnabled = requestPeriod === 'day';

    const date = dayjs()
      .add(period === PERIODS_IDS.TOMORROW ? 1 : 0, 'day')
      .format('YYYY-MM-DD');

    const natalPlanets = planets.map(p => {
      const { name, sign, house } = p;
      return { name, sign, house };
    });

    const natalHouses = houses.map(h => {
      const { house, sign } = h;
      return { house, sign };
    });

    const transitsData = await dispatch(getHoroscopesTransitsData());
    const transits: TransitWithDuration[] = transitsData?.[period.toLowerCase()] || [];

    if (!userHoroscopeV2LoadPromise[period]) {
      userHoroscopeV2LoadPromise[period] = getUserHoroscopeV2({
        period: [requestPeriod],
        date,
        pid,
        natalPlanets,
        natalHouses,
        transits,
        enableTips: todayContentTipsEnabled && extraContentEnabled,
        enableDoDont: todayContentDoDontEnabled && extraContentEnabled,
      });
    }

    const response = (await userHoroscopeV2LoadPromise[period]) ?? null;

    response?.forEach(item => {
      dispatch(addUserHoroscopeV2Item(item));
    });
    return response?.length ? HOROSCOPE_REQUEST_STATUSES.SUCCESS : HOROSCOPE_REQUEST_STATUSES.REQUEST_ERROR;
  };
};

let horoscopesTransitsPromise: ReturnType<typeof getHoroscopesTransits> | null = null;

export const getHoroscopesTransitsData = (): AppThunk<Promise<HoroscopesTransitsData | null>> => {
  return async (dispatch, getState) => {
    const {
      horoscope: { transits },
    } = getState();

    if (transits) {
      return transits;
    }

    const { birth, current } = await dispatch(getAstroInputs());

    if (horoscopesTransitsPromise === null) {
      horoscopesTransitsPromise = getHoroscopesTransits({ birth, current });
    }

    const res = await horoscopesTransitsPromise;
    horoscopesTransitsPromise = null;

    if (res.success) {
      const transitsData = res.data;
      dispatch(setHoroscopesTransits(transitsData));
      return transitsData;
    }

    return null;
  };
};

export const reloadUserHoroscopesV2 = () => {
  return (dispatch: AppDispatch) => {
    dispatch(initBirthChart());
    dispatch(resetUserHoroscopesV2Data());
  };
};

export const resetUserHoroscopesV2Data = () => {
  return (dispatch: AppDispatch) => {
    userHoroscopeV2LoadPromise = {};
    dispatch(resetUserHoroscopeV2());
    dispatch(setDoDontPredictions(null));
    dispatch(setTips([]));
    dispatch(setWidgetReloadIsRequired(true));
  };
};

let summaryContentWaitingTimeout: ReturnType<typeof setTimeout> | null = null;

export const getSummaryContent = (period: UserHoroscopeV2Period): AppThunk<Promise<HoroscopeContentV2>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { summaryWidgetConfig, todayContentTipsEnabled, todayContentDoDontEnabled },
      },
      horoscope: { userHoroscopes },
    } = getState();

    let horoscopeContent: HoroscopeContentV2 = {
      id: null,
      focus: '',
      troubles: '',
      tags: [],
      textBlocks: [],
      period,
      sourceVersion: 'v1',
    };

    if (summaryContentWaitingTimeout !== null) {
      clearTimeout(summaryContentWaitingTimeout);
    }

    const horoscopeV1 = userHoroscopes[HoroscopeTypes.zodiac].length
      ? (_.find(userHoroscopes[HoroscopeTypes.zodiac], { time_type: period.toLowerCase() }) ?? null)
      : null;

    if (horoscopeV1) {
      horoscopeContent = {
        ...horoscopeContent,
        tags: _.get(horoscopeV1, 'tags', []).map((item: TagBack) => {
          return {
            ...item,
            type: item.tag_type_id as unknown as TAG_TYPES,
          } as TagFront;
        }, []),
      };
    }

    const getContentFromHoroscopeV1 = () => {
      if (period === PERIODS_IDS.TODAY) {
        dispatch(processDoDontPredictions());
        dispatch(processDailyTipsPredictions());
      }

      if (horoscopeV1) {
        return {
          ...horoscopeContent,
          focus: _.get(horoscopeV1, 'focus', ''),
          textBlocks: [{ title: '', text: formatTextWithoutTags(_.get(horoscopeV1, 'text', '')) }],
        };
      }
      return horoscopeContent;
    };

    const getContentFromHoroscopeV2 = (item: UserHoroscopeV2Item): HoroscopeContentV2 => {
      if (period === PERIODS_IDS.TODAY) {
        dispatch(processDoDontPredictions());
        dispatch(processDailyTipsPredictions());
      }

      return {
        ...horoscopeContent,
        id: item.id ?? null,
        focus: item.data.powerAndFocus,
        troubles: item.data.troubles,
        textBlocks: item.data.blocks,
        sourceVersion: 'v2',
      };
    };

    if (summaryWidgetConfig?.enableHoroscopesV2?.[MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD[period]]) {
      let horoscopeV2 = selectUserHoroscopeV2(getState(), period);

      if (horoscopeV2) {
        return getContentFromHoroscopeV2(horoscopeV2);
      } else {
        const promiseTimeout = new Promise<HOROSCOPE_REQUEST_STATUSES>(resolve => {
          summaryContentWaitingTimeout = setTimeout(
            () => resolve(HOROSCOPE_REQUEST_STATUSES.WAITING_TIMEOUT_EXPIRED),
            summaryWidgetConfig?.maxResponseWaitingTime * 1000,
          );
        });

        const promiseLoadHoroscopeV2 = new Promise<HOROSCOPE_REQUEST_STATUSES>(resolve => {
          dispatch(loadUserHoroscopeV2(period)).then(result => resolve(result));
        });

        return Promise.race([promiseTimeout, promiseLoadHoroscopeV2])
          .then(response => {
            if (summaryContentWaitingTimeout !== null) {
              clearTimeout(summaryContentWaitingTimeout);
            }

            let responseStatus: HOROSCOPE_REQUEST_STATUSES = response;

            if (response === HOROSCOPE_REQUEST_STATUSES.SUCCESS) {
              Analytics.track('TodaysSummary_LoadHoroscopeV2_Success', {
                period: capitalize<UserHoroscopeV2Period>(period),
                tipsEnabled: todayContentTipsEnabled,
                doDontEnabled: todayContentDoDontEnabled,
              });

              horoscopeV2 = selectUserHoroscopeV2(getState(), period);

              if (horoscopeV2) {
                return getContentFromHoroscopeV2(horoscopeV2);
              }

              responseStatus = HOROSCOPE_REQUEST_STATUSES.DATA_NOT_RECEIVED;
            }

            Analytics.track('TodaysSummary_LoadHoroscopeV2_Failed', {
              period: capitalize<UserHoroscopeV2Period>(period),
              error: responseStatus,
            });

            return getContentFromHoroscopeV1();
          })
          .catch(() => {
            Analytics.track('TodaysSummary_LoadHoroscopeV2_Failed', {
              period: capitalize<UserHoroscopeV2Period>(period),
              error: HOROSCOPE_REQUEST_STATUSES.REQUEST_ERROR,
            });

            return getContentFromHoroscopeV1();
          });
      }
    }
    return getContentFromHoroscopeV1();
  };
};

export const processDoDontPredictions = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      remoteConfig: {
        remoteConfigParams: { summaryWidgetConfig, todayContentDoDontEnabled },
      },
      lunarCalendar: { predictions },
    } = state;

    if (todayContentDoDontEnabled && summaryWidgetConfig?.enableHoroscopesV2?.[MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD[PERIODS_IDS.TODAY]]) {
      const horoscopeV2 = selectUserHoroscopeV2(state, PERIODS_IDS.TODAY);

      if (horoscopeV2) {
        const doDont = horoscopeV2.data.doDont;

        if (!_.some(doDont, value => !value)) {
          const doDontPredictions: DoDontPredictions[] = [
            {
              id: 1,
              is_positive: true,
              content: doDont.do,
            },
            {
              id: 2,
              is_positive: false,
              content: doDont.dont,
            },
          ];

          Analytics.track('TodaysDoDont_Content_Loaded', {
            version: 'v2',
          });

          return dispatch(setDoDontPredictions(doDontPredictions));
        }
      }
    }

    const defaultPredictions = predictions[2]?.moon_in_sign || null;
    dispatch(setDoDontPredictions(defaultPredictions));

    Analytics.track('TodaysDoDont_Content_Loaded', {
      version: defaultPredictions ? 'v1' : 'error',
    });
  };
};

export const initFutureHoroscopeV2 = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { purchased },
      remoteConfig: {
        remoteConfigParams: {
          pushConfig: { dailyHoroscope },
        },
      },
    } = getState();
    const sign = selectZodiacSign(getState());
    const isAvailableToShow =
      dailyHoroscope === PUSH_BEHAVIOR.ALL ||
      (purchased && dailyHoroscope === PUSH_BEHAVIOR.PAID) ||
      (!purchased && dailyHoroscope === PUSH_BEHAVIOR.FREE);

    if (!isAvailableToShow) {
      return false;
    }

    Messaging.cancelLocalNotification(NOTIFICATION_UNIQ_ID.HOROSCOPE);

    const title = `${t('DAILY_HOROSCOPE')} ${ZODIAC_EMOJI_MAP[sign?.toLowerCase() || '']}`;

    const content = await dispatch(getSummaryContent(PERIODS_IDS.TOMORROW));

    let body = '';

    if (content.sourceVersion === 'v1') {
      const textBlock = content?.textBlocks?.find(item => !!item?.text?.length);
      body = formatTextWithoutTags(textBlock?.text || '');
    }

    if (content.sourceVersion === 'v2') {
      const tipTextBlock = content?.textBlocks?.find(item => item?.title === 'Tip');

      if (tipTextBlock) {
        body = formatTextWithoutTags(tipTextBlock?.text || '');
      } else {
        body = formatTextWithoutTags(content?.textBlocks?.[0]?.text || '');
      }
    }

    const horoscopeTime = new Date();
    horoscopeTime.setDate(horoscopeTime.getDate() + 1);
    horoscopeTime.setHours(8, 0);

    Messaging.schedulePlannedNotificationInternal({
      time: moment(horoscopeTime).unix() * 1000,
      title,
      body,
      uniqId: NOTIFICATION_UNIQ_ID.HOROSCOPE,
      id: HOROSCOPE_NOTIFICATION_ID,
    });

    return Promise.all([
      dispatch(getSummaryContent(PERIODS_IDS.WEEK)),
      dispatch(getSummaryContent(PERIODS_IDS.MONTH)),
      dispatch(getSummaryContent(PERIODS_IDS.YEAR)),
    ]);
  };
};
