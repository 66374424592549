import React, { FC, useEffect, useMemo, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import CLOSE from 'assets/icons/close.png';
import Analytics from 'analytics';
import { BEIGE_2, DARK_BLUE_GREY } from 'constants/colors';
import Text from 'components/text';
import { Buttons, MESSENGERS, MESSENGERS_HANDLERS_MAP, MESSENGERS_MODAL_IMAGES_MAP } from 'constants/messengers';
import { AppDispatch, RootState } from 'store';
import { t } from 'localization';
import Button from 'components/button';
import { MESSENGERS as MESSENGERS_MODAL } from 'constants/modals';
import usModalIsVisibly from 'hooks/use-modal-is-visibly';
import { closeCurrentModal } from 'store/modals/actions';
import { ASTRO_CALENDAR_PLACE, ASTRO_CALENDAR_TODO_TYPE } from 'screens/astro-calendar/constants';
import { addActiveTodo } from 'store/astro-calendar/actions';

import ENVELOP from './images/envelop.png';
import SelectItem from './components/select-item';

interface Props {}

const MessengersModal: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isVisible = usModalIsVisibly(MESSENGERS_MODAL);
  const messengersModalConfig = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.messengersModalConfig);
  const modalParams = useSelector((state: RootState) => state.modals.params);

  const [activeSelectors, setButton] = useState<MESSENGERS[]>([]);

  const originPlace = modalParams?.place ?? 'notification';
  const title = messengersModalConfig?.title?.length ? messengersModalConfig.title : t('MESSENGERS_MODAL.TITLE');
  const buttonTitle = messengersModalConfig?.buttonTitle?.length ? messengersModalConfig.buttonTitle : t('MESSENGERS_MODAL.BUTTON');
  const selects = useMemo(
    () =>
      Object.keys(messengersModalConfig?.buttons || {}).map<Buttons>(button => {
        return {
          name: button as MESSENGERS,
          image: MESSENGERS_MODAL_IMAGES_MAP[button as MESSENGERS],
        };
      }),
    [messengersModalConfig],
  );

  const handleModalShow = () => {
    Analytics.track('Messengers_Modal_Open', { place: originPlace });
  };

  const handleClose = () => {
    dispatch(closeCurrentModal());
  };

  const handleButtonPress = () => {
    Analytics.track('Messengers_Button_Press', { selected: activeSelectors, place: originPlace });
    activeSelectors.forEach(item => {
      const handler = MESSENGERS_HANDLERS_MAP[item];
      dispatch(handler());
    });

    if (originPlace === ASTRO_CALENDAR_PLACE) {
      dispatch(addActiveTodo({ type: ASTRO_CALENDAR_TODO_TYPE.DAILY_FORECAST }));
    }
    handleClose();
  };

  const handleSelectPress = (select: MESSENGERS) => {
    setButton(prev => {
      if (prev.includes(select)) {
        return prev.filter(item => item !== select);
      }

      return [...prev, select];
    });
  };

  useEffect(() => {
    const activeButtons = Object.entries(messengersModalConfig?.buttons || {})?.reduce<MESSENGERS[]>((acc, button) => {
      const [key, value] = button as [MESSENGERS, boolean];
      if (value) {
        return [...acc, key];
      }

      return acc;
    }, []);

    setButton(activeButtons);
  }, [messengersModalConfig]);

  if (!selects.length) {
    return null;
  }

  return (
    <Modal
      isVisible={isVisible}
      backdropOpacity={0.8}
      style={styles.modal}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      onModalShow={handleModalShow}>
      <View style={styles.wrapper}>
        <TouchableOpacity style={styles.iconContainer} onPress={handleClose}>
          <Image style={styles.icon} source={CLOSE} />
        </TouchableOpacity>
        <View style={styles.container}>
          <Image source={ENVELOP} />
          <Text font="Philosopher" style={styles.title}>
            {title}
          </Text>
          <View style={styles.messengerItems}>
            {selects.map(({ image, name }) => {
              return <SelectItem key={name} image={image} name={name} onPress={handleSelectPress} isActive={activeSelectors.includes(name)} />;
            })}
          </View>
          <Button onPress={handleButtonPress} title={buttonTitle} style={styles.button} disabled={!activeSelectors.length} />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  wrapper: {
    paddingVertical: paddingVertical(20),
    borderTopRightRadius: sw(30),
    borderTopLeftRadius: sw(30),
    paddingHorizontal: paddingHorizontal(15),
    backgroundColor: DARK_BLUE_GREY,
  },
  iconContainer: {
    alignSelf: 'flex-end',
    marginBottom: paddingVertical(5),
  },
  icon: {
    tintColor: BEIGE_2,
  },
  container: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: paddingVertical(10),
    paddingHorizontal: paddingHorizontal(25),
  },
  title: {
    color: BEIGE_2,
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(32),
    textAlign: 'center',
    marginBottom: paddingVertical(31),
    marginTop: paddingVertical(25),
  },
  messengerItems: {
    width: '100%',
  },
  button: {
    marginTop: paddingVertical(30),
  },
});

export default MessengersModal;
