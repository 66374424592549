import { ImageSourcePropType } from 'react-native';

export const ASTROLOGY = '/astrology-questions';
export const ADD_MESSAGE = `${ASTROLOGY}/add-message`;
export const ACCRUE_TIME = `${ASTROLOGY}/accrue-time`;
export const ADD_REVIEW = `${ASTROLOGY}/add-review`;
export const ADD_TIME = `${ASTROLOGY}/add-time`;
export const GET_HISTORY = `${ASTROLOGY}/history`;
export const GET_ANSWERS = `${ASTROLOGY}/answers`;
export const GET_ASTROLOGERS_PROFILES = `${ASTROLOGY}/astrologers`;
export const GET_CHATS = `${ASTROLOGY}/chats`;
export const START_CHAT = `${ASTROLOGY}/start`;
export const PING_CHAT = `${ASTROLOGY}/ping`;
export const GET_BALANCE = `${ASTROLOGY}/balance`;
export const CHARGE_TIME = `${ASTROLOGY}/charge-time`;
export const USE_TEMPLATE = `${ASTROLOGY}/use-template`;
export const READ_MESSAGES = `${ASTROLOGY}/view`;
export const GET_CODE = `${ASTROLOGY}/check-code`;
export const DAILY_REWARDS = `${ASTROLOGY}/daily-rewards`;
export const LOG_CHAT = `${ASTROLOGY}/log`;
export const GET_MESSAGES = `${ASTROLOGY}/messages`;

const METADATA_TYPES = ['tarotCard', 'palmReadingLeftHandData', 'palmScanData', 'voiceMessage'] as const;
type MetadataType = (typeof METADATA_TYPES)[number];

export type HistoryItemMetadata = {
  place?: string;
  promptID?: number;
  notificationCategory?: string;
  url?: string;
  type?: MetadataType;
  hiddenMessage?: string;
  palmImage?: string;
};

export interface HistoryItem {
  created_at: string;
  is_viewed: 0 | 1;
  message: string;
  type: string;
  animated: boolean;
  is_answered: 0 | 1;
  is_blurred?: 0 | 1;
  needAnswer?: 0 | 1;
  id: number | string;
  is_viewed_by_user: 0 | 1;
  metadata?: any | null;
}

export interface MessagesPage {
  messages: HistoryItem[];
  isNextPage: boolean;
  pageCount: number;
  messagesCount: number;
}

export interface StartChatAnswer {
  chat_id: number;
  error?: string;
  is_unlimited: boolean;
}

export interface ChatAnswer {
  isBlurred: boolean;
  isRecording: number;
  isTyping: number;
  messages: HistoryItem[];
}

export interface Chats {
  astrologer_id: string | number;
  created_at: string;
  lastMessage: HistoryItem;
}

export interface Astrologist {
  age: number;
  astrologer_id: number | string;
  experience: string;
  expertise: string;
  gender: 'Male' | 'Female';
  location: string;
  name: string;
  origin: string;
  rating: number;
  reviews: number;
  short_description: string;
  skills: string[];
  avatar: ImageSourcePropType;
  is_active: 0 | 1;
}

export interface EndChatData {
  reason: 'chat_end' | 'time_up' | 'chat_in_background' | 'chat_in_foreground';
  balance?: number;
}

export interface Balances {
  balance: number;
  freeBalance: number;
}

export interface ChargeTime extends Balances {}

export interface TimeBalance extends Balances {
  isRewardedForFirstUse: number;
  isRewardedForRenew: number;
  isRewardedForUnpaidUser: number;
  lastTransactionDate: null | string;
  lastTransactionStatus: null | string;
  totalTransactionsCount: number;
}

export type ChatPlatforms = 'android' | 'ios' | 'rnw' | 'pwa' | 'undetected';

export interface DailyRewards extends Balances {
  error?: string;
}

export interface AddPromocodeRequestParams {
  code: string;
  increaseFreeBalance: boolean;
}

export interface AddPromocodeResponse {
  code: string;
  balance: number;
  seconds: number;
}

export interface AccrueTimeRequestParams {
  time: number;
  type: 'unpaid' | 'first' | 'cycle';
  increaseFreeBalance: boolean;
}
