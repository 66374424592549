export enum FIREBASE_AUTH_ERRORS {
  DEFAULT = 'DEFAULT',
  CANCELLED = 'CANCELLED',
  NO_ID_TOKEN = 'NO_ID_TOKEN',
  INVALID_EMAIL = 'INVALID_EMAIL',
  NEW_USER = 'NEW_USER',
  TOO_MANY = 'TOO_MANY_ATTEMPTS_TRY_LATER',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
}
