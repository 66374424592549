import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState, AppThunk } from 'store';
import { NOTIFICATIONS } from 'constants/notification-center';
import Analytics from 'analytics';

import { SET_QUEUE, SET_PRESSED, SET_HIDDEN, SET_CURRENT_NOTIFICATION } from './types';

const setQueue = createAction(SET_QUEUE);
const setPressed = createAction(SET_PRESSED);
const setHidden = createAction(SET_HIDDEN);
export const setCurrentNotification = createAction(SET_CURRENT_NOTIFICATION);

let queueUpdateTimeout: any;
const notificationBuffer: NOTIFICATIONS[] = [];

export const setNotificationsHidden = (hide: boolean): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      notificationCenter: { isHidden },
    } = getState();
    if (isHidden !== hide) {
      dispatch(setHidden(hide));
    }
  };
};

export const addNotificationToQueue = (id: NOTIFICATIONS): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      notificationCenter: { queue },
      astrologers: {
        notifications: { notification: astrologersNotification },
      },
    } = getState();
    const isNotificationIdInArray = queue?.includes?.(id);

    if (!isNotificationIdInArray) {
      clearTimeout(queueUpdateTimeout);

      const params = {
        id,
      } as any;

      if (astrologersNotification) {
        params.astrologersNotificationMessageId = astrologersNotification.message_id;
        params.astrologersNotificationCategory = astrologersNotification.category;
      }

      Analytics.track('Notification_Center_Show', params);
      notificationBuffer.push(id);

      queueUpdateTimeout = setTimeout(() => {
        const newQueue = [...notificationBuffer, ...queue];
        dispatch(setQueue(newQueue));
        notificationBuffer.length = 0;
      }, 1000);
    }
  };
};

export const removeNotificationFromQueue = (id: NOTIFICATIONS): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      notificationCenter: { queue, wasPressed },
    } = getState();
    const newQueue = queue.filter(i => i !== id);
    dispatch(setQueue(newQueue));

    const pressed = [...wasPressed, id];
    dispatch(setPressed(pressed));
  };
};

export const replaceItems = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      notificationCenter: { queue },
    } = getState();
    const splittedQueue = queue.slice(0, queue.length - 1);
    const newQueue = [queue[queue.length - 1], ...splittedQueue];

    dispatch(setQueue(newQueue));
  };
};
