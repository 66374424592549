import { ADVISOR_NOTIFICATIONS } from 'constants/notification-center';

import api from '..';

import { AdvisorNotification, SuggestsResponseData } from './interfaces';

const ASTROLOGY_QUESTIONS = '/astrology-questions';

export const sendAstrologersQuestion = (question: string): Promise<null> => {
  return api.fetch(`${ASTROLOGY_QUESTIONS}/create-question`, {
    method: 'POST',
    data: {
      question,
    },
  });
};

export const getAstrologersSuggests = (): Promise<SuggestsResponseData> => {
  return api.fetch(`${ASTROLOGY_QUESTIONS}/suggests`, {
    method: 'GET',
  });
};

export const getNotification = (categories: ADVISOR_NOTIFICATIONS[], transit: string): Promise<AdvisorNotification> => {
  return api.fetch(`${ASTROLOGY_QUESTIONS}/get-notification`, {
    method: 'POST',
    data: {
      categories,
      params: { transit },
    },
  });
};
