import React, { FC, memo, useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View, Image, LayoutChangeEvent } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import Animated, { cancelAnimation, runOnJS, useAnimatedStyle, useSharedValue, withDelay, withTiming } from 'react-native-reanimated';
import { CommonActions } from '@react-navigation/native';

import Analytics from 'analytics';
import { t } from 'localization';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import * as ROUTES from 'constants/routes';
import Icon from 'components/icon';
import { AppDispatch, RootState } from 'store';
import { setActiveNotification, setOriginPlace } from 'store/astro-calendar/actions';
import { navigationRef } from 'store/navigation/actions';
import { DEFAULT_HEADER_OFFSET } from 'constants/sizes';

import BONUS_ICON from '../images/medal-active.png';
import { ASTRO_CALENDAR_ORIGIN } from '../constants';

const AstroCalendarNotification: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const activeNotification = useSelector((state: RootState) => state.astroCalendar.activeNotification);
  const notificationConfig = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.astroCalendarNotificationConfig);
  const [trackHeight, setTrackHeight] = useState<number>(sw(200));
  const showNotification = activeNotification !== null;

  const handleClose = () => {
    Analytics.track('AstroCalendar_Notification_Close');
    dispatch(setActiveNotification(null));
  };

  const handlePress = () => {
    Analytics.track('AstroCalendar_Notification_Click');

    dispatch(setOriginPlace(ASTRO_CALENDAR_ORIGIN.NOTIFICATION));

    navigationRef.current?.dispatch(() => {
      return CommonActions.reset({
        index: 1,
        routes: [
          {
            name: ROUTES.MAIN,
            params: {
              screen: ROUTES.DAILY,
            },
          },
          {
            name: ROUTES.ASTRO_CALENDAR,
            params: { date: activeNotification?.date },
          },
        ],
      });
    });

    dispatch(setActiveNotification(null));
  };

  const translateYSharedValue = useSharedValue<number>(-trackHeight);

  const translateAnimatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateY: translateYSharedValue.value,
        },
      ],
    }),
    [translateYSharedValue.value],
  );

  const trackShowEvent = () => {
    Analytics.track('AstroCalendar_Notification_Show');
  };

  useEffect(() => {
    translateYSharedValue.value = withDelay(
      showNotification ? (notificationConfig?.displayTimeout ?? 0) * 1000 : 0,
      withTiming(
        showNotification ? 0 : -trackHeight,
        {
          duration: showNotification ? 300 : 0,
        },
        success => {
          if (showNotification && success) {
            runOnJS(trackShowEvent)();
          }
        },
      ),
    );

    return () => cancelAnimation(translateYSharedValue);
  }, [notificationConfig, showNotification, trackHeight, translateYSharedValue]);

  const handleTrackLayout = (e: LayoutChangeEvent) => {
    const { height } = e.nativeEvent.layout;
    setTrackHeight(height + sw(5));
  };

  if (!notificationConfig?.enabled) {
    return null;
  }

  return (
    <Animated.View style={[styles.root, translateAnimatedStyle]} onLayout={handleTrackLayout}>
      <TouchableOpacity style={styles.mainContainer} onPress={handlePress}>
        <TouchableOpacity style={styles.crossContainer} onPress={handleClose}>
          <Icon name={'cross'} size={sw(14)} color={COLORS.DARK_GREY_BLUE} />
        </TouchableOpacity>
        <View style={styles.textContainer}>
          <Image source={BONUS_ICON} style={styles.icon} />
          <View style={styles.textWrapper}>
            <Text style={styles.title}>{t('ASTRO_CALENDAR.NOTIFICATION.TITLE')}</Text>
            <Text style={styles.subtitle}>{t('ASTRO_CALENDAR.NOTIFICATION.SUBTITLE')}</Text>
          </View>
        </View>
        <View style={styles.arrowContainer}>
          <Icon name={'arrow'} size={sw(18)} color={COLORS.DARK_GREY_BLUE} />
        </View>
      </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    backgroundColor: COLORS.BEIGE,
    borderBottomRightRadius: sw(15),
    borderBottomLeftRadius: sw(15),
    position: 'absolute',
    top: 0,
    overflow: 'hidden',
    paddingTop: DEFAULT_HEADER_OFFSET,
    zIndex: 999,
  },
  mainContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: COLORS.BEIGE,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  crossContainer: {
    width: '10%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    width: '83%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingVertical: paddingHorizontal(15),
  },
  textWrapper: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: paddingHorizontal(15),
  },
  icon: {
    height: sw(46),
    width: sw(35),
    resizeMode: 'contain',
  },
  title: {
    fontWeight: '600',
    fontSize: fs(14),
    lineHeight: fs(19),
    textAlign: 'left',
    color: COLORS.DARK_BLUE_GREY,
  },
  subtitle: {
    fontWeight: '400',
    fontSize: fs(13),
    lineHeight: fs(22),
    textAlign: 'left',
    color: COLORS.DARK_GREY_BLUE,
    marginTop: paddingVertical(5),
  },
  arrowContainer: {
    width: '7%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: paddingHorizontal(14),
  },
});

export default memo(AstroCalendarNotification);
