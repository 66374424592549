import React, { FC, useState, useCallback, useEffect } from 'react';
import { StyleSheet, View, Image, Text } from 'react-native';
import Modal from 'react-native-modal';

import Analytics from 'analytics';
import { t2 } from 'localization';
import { useAppDispatch, useAppSelector } from 'store';
import { rate, sendRate, ratedHandle } from 'store/rate-us/actions';
import { closeCurrentModal } from 'store/modals/actions';
import { addActiveTodo } from 'store/astro-calendar/actions';
import { FEATURES } from 'store/rate-us/types';
import { navigateToSupport } from 'store/navigation/actions';
import { APP_NAME } from 'constants/general';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import APP_ICON from 'assets/icons/app-icons/app-icon.png';
import { ASTRO_CALENDAR_TODO_TYPE } from 'screens/astro-calendar/constants';
import useModalIsVisible from 'hooks/use-modal-is-visibly';

import Divider from './components/divider';
import Star from './components/star';
import Button from './components/button';
import StarActive from './images/star_active.svg';
import StarInactive from './images/star_inactive.svg';
import StarActive1 from './images/star_active_1.svg';
import StarInactive1 from './images/star_inactive_1.svg';

interface Props {}

const RateUsNativeModal: FC<Props> = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [rating, setRating] = useState(-1);
  const dispatch = useAppDispatch();
  const isVisible = useModalIsVisible(MODALS.RATE_US_NATIVE);
  const minRate = useAppSelector(state => state.remoteConfig.remoteConfigParams?.rateUs.minRate);
  const modalParams = useAppSelector(state => state.modals.params);
  const isNegative = isSubmitted && rating < minRate;

  useEffect(() => {
    if (isVisible) {
      Analytics.track('RateUs_ShowCustom', { feature: modalParams?.feature, type: 'new' });
    }
  }, [isVisible]);

  const handleModalClose = () => {
    dispatch(closeCurrentModal());

    setTimeout(() => {
      setRating(-1);
      setSubmitted(false);
    }, 500);
  };

  const handleStarPress = useCallback((value: number) => {
    setRating(value);
  }, []);

  const handleCancelPress = () => {
    handleModalClose();
  };

  const handleSubmitPress = () => {
    setSubmitted(true);
    sendRate(rating);
    dispatch(rate(rating, modalParams?.feature));
  };

  const handleReportAProblemPress = () => {
    handleModalClose();
    Analytics.track('RateUs_ReportProblem_Click');
    dispatch(navigateToSupport(false));
    dispatch(rate(rating, modalParams?.feature));
  };

  const handleOkPress = () => {
    handleModalClose();
    dispatch(ratedHandle(rating));

    if (modalParams?.feature === FEATURES.ASTRO_CALENDAR) {
      dispatch(addActiveTodo({ type: ASTRO_CALENDAR_TODO_TYPE.RATE_US }));
    }
  };

  const ActiveStar = !isSubmitted ? StarActive : StarActive1;
  const InactiveStar = !isSubmitted ? StarInactive : StarInactive1;

  return (
    <Modal
      style={styles.wrap}
      isVisible={isVisible}
      statusBarTranslucent
      backdropOpacity={0.4}
      animationInTiming={200}
      animationIn="zoomIn"
      animationOut="fadeOut">
      <View style={styles.container}>
        <Image source={APP_ICON} style={styles.appIcon} />
        <Text style={styles.title}>{isSubmitted ? t2('RATE_US_NATIVE.TITLE_RATED') : t2('RATE_US_NATIVE.TITLE', { appName: APP_NAME })}</Text>

        {((isSubmitted && isNegative) || !isSubmitted) && (
          <Text style={styles.text}>{isNegative ? t2('RATE_US_NATIVE.REPORT_PROBLEM_TEXT') : t2('RATE_US_NATIVE.TEXT')}</Text>
        )}

        <View style={[styles.stars, !isSubmitted && styles.starsNotSubmitted]}>
          {!isSubmitted && <Divider type="horizontal" />}
          <View style={[styles.starsList, !isSubmitted && styles.starsListNotSubmitted]}>
            {[1, 2, 3, 4, 5].map(value => (
              <Star key={value} value={value} onPress={handleStarPress} icon={rating >= value ? ActiveStar : InactiveStar} isDisabled={isSubmitted} />
            ))}
          </View>
        </View>

        <Divider type="horizontal" />
        <View style={[styles.btns, isSubmitted && isNegative && styles.btnsCol]}>
          {!isSubmitted ? (
            rating < 0 ? (
              <Button title={t2('RATE_US_NATIVE.BUTTONS.NOT_NOW')} style={styles.btnAlone} onPress={handleCancelPress} />
            ) : (
              <>
                <Button title={t2('RATE_US_NATIVE.BUTTONS.CANCEL')} style={styles.btnHalf} onPress={handleCancelPress} />
                <Divider type="vertical" />
                <Button title={t2('RATE_US_NATIVE.BUTTONS.SUBMIT')} style={styles.btnHalf} isBoldTitle onPress={handleSubmitPress} />
              </>
            )
          ) : (
            <>
              {isNegative && (
                <>
                  <Button title={t2('RATE_US_NATIVE.BUTTONS.REPORT_PROBLEM')} isBoldTitle onPress={handleReportAProblemPress} />
                  <Divider type="horizontal" />
                </>
              )}
              <Button
                title={t2('RATE_US_NATIVE.BUTTONS.OK')}
                style={[styles.btnAlone, isNegative && styles.btnAloneNegative]}
                onPress={handleOkPress}
              />
            </>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default RateUsNativeModal;

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    margin: 0,
    alignContent: 'center',
    justifyContent: 'center',
  },
  container: {
    alignSelf: 'center',
    width: 269,
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 20,
    backgroundColor: COLORS.NATIVE_IOS_MODAL_BG_COLOR,
    borderRadius: 16,
    overflow: 'hidden',
  },
  appIcon: {
    width: 60,
    height: 60,
  },
  title: {
    marginTop: 16,
    textAlign: 'center',
    fontSize: 17,
    lineHeight: 22,
    letterSpacing: -0.41,
    fontWeight: '600',
    color: COLORS.BLACK,
  },
  text: {
    textAlign: 'center',
    fontSize: 15,
    lineHeight: 20,
    letterSpacing: -0.24,
    fontWeight: '400',
    color: COLORS.BLACK,
  },
  stars: {
    width: '100%',
  },
  starsNotSubmitted: {
    marginTop: 20,
  },
  starsList: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 6,
    paddingBottom: 10,
    gap: 9,
  },
  starsListNotSubmitted: {
    paddingTop: 12,
    paddingBottom: 12,
    gap: 20,
  },
  btns: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnsCol: {
    flexDirection: 'column',
  },
  btnAlone: {
    flexBasis: '100%',
  },
  btnAloneNegative: {
    flexBasis: 'auto',
  },
  btnHalf: {
    flexBasis: '50%',
  },
});
