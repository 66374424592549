import { useState, useEffect, useCallback } from 'react';

import { useIsForeground } from 'hooks/use-is-foreground';
import { getDiffSecondsFromNow } from 'utils/date';

const useCountDown = (date: Date, onTimeEnd?: () => void) => {
  const isForeground = useIsForeground();
  const [remainingSeconds, setRemainingSeconds] = useState(getDiffSecondsFromNow(date));

  const updateRemainingTime = useCallback(() => {
    setRemainingSeconds(getDiffSecondsFromNow(date));
  }, [date]);

  useEffect(() => {
    if (remainingSeconds <= 0) {
      if (typeof onTimeEnd === 'function') {
        onTimeEnd();
      }
    }
  }, [remainingSeconds, onTimeEnd]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (isForeground) {
      updateRemainingTime();
      interval = setInterval(updateRemainingTime, 1000);
    }

    return () => clearInterval(interval);
  }, [isForeground]);

  const formattedTime = useCallback(() => {
    const hours = Math.floor(remainingSeconds / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const seconds = remainingSeconds % 60;

    if (hours > 0) {
      return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
    return `${minutes}:${String(seconds).padStart(2, '0')}`;
  }, [remainingSeconds]);

  return {
    isTimeUp: remainingSeconds <= 0,
    remainingSeconds: remainingSeconds,
    formattedTime: formattedTime(),
  };
};

export default useCountDown;
