import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import * as ROUTES from 'constants/routes';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { navigate, reset } from 'store/navigation/actions';
import { ASTROLOGERS_OPEN_PLACE } from 'screens/advisors/constants';
import { isValidOnboardingPage } from 'screens/advisors/screens/onboarding/utils';
import { submitQuestion } from 'store/astrologers/chat/actions';
import { setUserParams } from 'store/profile/actions';

import { ADVISORS_ONB_PAGES_IDS, TYPES } from './types';

const setCompletedAction = createAction(TYPES.SET_IS_COMPLETED);
export const setOnboardingFlow = createAction(TYPES.SET_ONBOARDING_FLOW);
export const setOnboardingStep = createAction(TYPES.SET_STEP);
export const updateData = createAction(TYPES.UPDATE_DATA);

export const setCompleted =
  (trackEv = true): AppThunk =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        onboarding: { isCompleted },
      },
    } = getState();

    if (!isCompleted) {
      if (trackEv) {
        Analytics.track('AdvisorsOnb_Completed');
      }

      dispatch(
        setUserParams({
          is_advisor_onboarding_completed: true,
        }),
      );
      dispatch(setCompletedAction());
    }
  };

export const initOnboarding = (): AppThunk => (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    remoteConfig: {
      remoteConfigParams: { advisorsOnboardingFlow },
    },
    astrologers: {
      onboarding: { flow, isCompleted },
    },
    astrologers: { time },
    profile: {
      profileData: { userParams },
    },
  } = getState();

  if (!advisorsOnboardingFlow?.length || userParams?.is_advisor_onboarding_completed) {
    return dispatch(setCompleted(false));
  }

  if (!flow.length) {
    const availableFlow = advisorsOnboardingFlow
      .map(step => step?.toUpperCase?.())
      .filter(page => {
        if (page === ADVISORS_ONB_PAGES_IDS.BONUS && time?.seconds === 0) {
          return false;
        }
        // @ts-ignore
        return isValidOnboardingPage(page);
      });

    dispatch(setOnboardingFlow(availableFlow));
  } else {
    if (!isCompleted) {
      dispatch(setOnboardingStep(flow[0]));
    }
  }
};

export const setOnboardingCompleted = (): AppThunk => (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    astrologers: {
      core: { astrologist },
      onboarding: {
        data: { question },
      },
    },
    remoteConfig: {
      remoteConfigParams: { advisorsOnboardingSkipConnection },
    },
  } = getState();

  dispatch(setCompleted());

  if (question) {
    dispatch(submitQuestion(question));
  }

  const place = ASTROLOGERS_OPEN_PLACE.ADVISORS_ONBOARDING;

  if (advisorsOnboardingSkipConnection) {
    return navigate(ROUTES.ADVISORS_CHAT, { id: astrologist.astrologer_id, place });
  }

  reset(ROUTES.ADVISORS_CONNECTION, { place });
};

export const goToNextOnboardingStep = (): AppThunk => (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    astrologers: {
      onboarding: { flow, step },
    },
  } = getState();

  const nextStep = step !== null ? flow.indexOf(step) + 1 : 0;
  const page = flow[nextStep];

  if (!page) {
    return dispatch(setOnboardingCompleted());
  }

  dispatch(setOnboardingStep(page));
};
