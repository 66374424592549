import React, { FC } from 'react';
import { Pressable, StyleSheet, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Analytics from 'analytics';
import { requestPermissions } from 'store/notification/actions';
import { AppDispatch, RootState } from 'store';

interface Props {}

const WebNotificationRequestOverlay: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isVisible = useSelector((state: RootState) => state.notification.isWebNotificationRequestOverlayVisible);

  const handlePress = () => {
    Analytics.track('notification_request_overlay_click');
    dispatch(requestPermissions());
  };

  if (Platform.OS !== 'web' || !isVisible) {
    return null;
  }

  const currentPermission = window?.Notification?.permission;
  if (['denied', 'granted'].includes(currentPermission)) {
    return null;
  }

  return <Pressable style={[StyleSheet.absoluteFill, styles.container]} onPress={handlePress} />;
};

const styles = StyleSheet.create({
  container: {
    zIndex: 999,
  },
});

export default WebNotificationRequestOverlay;
