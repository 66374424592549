import { createSelector } from 'reselect';

import { Astrologist } from 'api/astrology-chat/constants';
import { RootState } from 'store';
import { t } from 'localization';

const getAstrologersChats = (state: RootState) => state.astrologers.chat.chats;
const getAstrocartography = (state: RootState) => state.astrologers.core.isAstrocartographyEnabled;
const getAdvisors = (state: RootState) => state.astrologers.core.allAstrologers;

export const getChatsDataWithReviews = createSelector([getAstrologersChats], chats => {
  const chatsValues = Object.values(chats);
  return chatsValues.filter(chat => chat?.isReviewCompleted && chat?.rate);
});

export const getAdvisorsPositiveReview = createSelector([getChatsDataWithReviews], reviews => {
  const positiveReview = reviews.find(review => !!review?.rate && review?.rate >= 4);
  return positiveReview?.rate;
});

export const selectChatMessagesById = createSelector([getAstrologersChats, (_state, id: Astrologist['astrologer_id']) => id], (chats, id) => {
  return chats?.[id]?.messages || [];
});

export const getInteractingStatusesByChatId = createSelector([getAstrologersChats, (_state, id: Astrologist['astrologer_id']) => id], (chats, id) => {
  return [!!chats?.[id]?.isTyping, !!chats?.[id]?.isRecording];
});

export const getAstrocartographyData = createSelector(
  [getAstrocartography, getAdvisors, (_state, id: Astrologist['astrologer_id']) => id],
  (isAstrocartographyEnabled, advisors, id) => {
    if (!isAstrocartographyEnabled) {
      return null;
    }

    const advisorName = advisors.find(item => `${item.astrologer_id}` === `${id}`)?.name || '';
    const message = t('ASTROCARTOGRAPHY.CHAT_MESSAGE', { name: advisorName });

    return message;
  },
);

export const getChatMessagesStatus = createSelector([getAstrologersChats, (_state, id: Astrologist['astrologer_id']) => id], (chats, id) => {
  return !!chats?.[id]?.isMessagesFull;
});
