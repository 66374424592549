import React, { useEffect, FC } from 'react';
import { Modal, StyleSheet } from 'react-native';
import type { DimensionValue } from 'react-native';
import queryString from 'query-string';

import Analytics from 'analytics';
import { useAppDispatch, useAppSelector } from 'store';
import Iframe from 'components/web/iframe';
import { fetchReports } from 'store/upsails/actions';
import { isSolidgatePaymentService } from 'store/billing/selectors';
import { closeCurrentModal } from 'store/modals/actions';
import { goToNextScreen } from 'store/birth-chart/actions';
import * as MODALS from 'constants/modals';
import { WEB_POST_MESSAGE_STATUS } from 'modules/payments/constants';

interface Props {}

const WebBirthChartModal: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const isSolidgate = useAppSelector(isSolidgatePaymentService);
  const idfm = useAppSelector(state => state.auth.webUUID);
  const isVisible = useAppSelector(state => state.modals.activeModal === MODALS.WEB_BIRTH_CHART);
  const email = useAppSelector(state => state.profile.profileData?.email);

  useEffect(() => {
    if (isVisible) {
      Analytics.track('Web_BirthChart_Modal_Open');
    }
  }, [isVisible]);

  const query: any = {
    idfm,
    email: email ? email : undefined,
    skipOneClick: !isSolidgate ? null : undefined,
  };

  const url = queryString.stringifyUrl({
    url: process.env.REACT_APP_WEB_BIRTH_CHART_REPORT_URL as unknown as string,
    query,
  });

  const successPurchasedHandle = () => {
    dispatch(closeCurrentModal());
    dispatch(goToNextScreen());
  };

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event?.data?.source === 'birth-chart-report') {
        const status = event?.data?.status as WEB_POST_MESSAGE_STATUS;

        switch (status) {
          case WEB_POST_MESSAGE_STATUS.SUCCESS:
            Analytics.track('Web_BirthChart_Modal_Success');
            dispatch(fetchReports());
            successPurchasedHandle();
            break;
          case WEB_POST_MESSAGE_STATUS.SKIPED:
            Analytics.track('Web_BirthChart_Modal_Skip');
            break;
          case WEB_POST_MESSAGE_STATUS.CLOSED:
            Analytics.track('Web_BirthChart_Modal_Close');
            break;
          default:
            break;
        }

        if (status !== WEB_POST_MESSAGE_STATUS.LOADED) {
          dispatch(closeCurrentModal());
        }
      }
    };
    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  const closeModal = () => dispatch(closeCurrentModal());

  return (
    <Modal visible={isVisible && !!url} style={styles.iframe_modal}>
      <Iframe id="birthchartIframe" src={url} onError={closeModal} />
    </Modal>
  );
};

export default WebBirthChartModal;

const styles = StyleSheet.create({
  iframe_modal: {
    width: '100vw' as DimensionValue,
    height: '100vh' as DimensionValue,
    top: 0,
    left: 0,
  },
});
