import React, { FC } from 'react';

const Splash: FC = () => {
  return (
    <div className="page-loader">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Splash;
