export type Priorities = {
  [key in string]: number;
};

export const orderByPriority = (arr: any[], key: string, priorities: Priorities) => {
  return arr.sort((a, b) => {
    const important_a = priorities[a[key]];
    const important_b = priorities[b[key]];
    let ret;

    if (important_a && !important_b) {
      ret = -1;
    } else if (important_b && !important_a) {
      ret = 1;
    } else if (important_a && important_b) {
      ret = important_a - important_b;
    } else {
      ret = 0;
    }

    return ret;
  });
};

export function mergeMessagesByKey<T>(allMessages: T[], key: keyof T): T[] {
  const uniqueMessages = new Map<any, T>();

  allMessages.forEach(message => {
    uniqueMessages.set(message[key], message);
  });

  return Array.from(uniqueMessages.values());
}
