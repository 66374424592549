import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';

import * as COLORS from 'constants/colors';

interface Props {
  type: 'horizontal' | 'vertical';
}

const Divider: FC<Props> = ({ type }) => <View style={[styles.divider, type === 'vertical' && styles.vertical]} />;

export default Divider;

const styles = StyleSheet.create({
  divider: {
    width: '100%',
    height: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.NATIVE_IOS_MODAL_DIVIDER_COLOR,
  },
  vertical: {
    width: 1,
    height: '100%',
  },
});
