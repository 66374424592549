import { createAction } from 'redux-actions';
import _ from 'lodash';
import { Platform } from 'react-native';

import { addNotificationToQueue, removeNotificationFromQueue } from 'store/notification-center/actions';
import { NOTIFICATIONS, ADVISOR_NOTIFICATIONS } from 'constants/notification-center';
import { AppDispatch, AppGetState } from 'store';
import { getNotification } from 'api/astrologers';
import { AdvisorNotification } from 'api/astrologers/interfaces';
import { getNextMidnight, getDiffMinutesFromNow, getDiffDaysFromNow } from 'utils/date';
import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components/const';
import { isStandalone } from 'utils/pwa';
import { getPromocode } from 'api/astrology-chat';
import { generateAppLink } from 'store/app/actions';
import { setUserParams } from 'store/profile/actions';
import { ADVISORS_CHAT, ADVISORS_ROUTES, BIRTH_CHART_ROUTES, PATH_NAMES, PATH_ROUTES_MAP } from 'constants/routes';
import Analytics from 'analytics';

import { handleExternalTrigger } from '../core/actions';

import { TYPES, AstrologerNotification, TriggerNotification } from './types';

const setLastContextNotificationId = createAction(TYPES.SET_LAST_CONTEXT_ID);
const incRandomCounter = createAction(TYPES.INC_RANDOM_COUNTER);
const resetRandomCounter = createAction(TYPES.RESET_RANDOM_COUNTER);
const setPromocodeAddedInPwa = createAction(TYPES.SET_PROMOCODE_ADDED_IN_PWA);
export const setNotification = createAction(TYPES.SET);
export const setNotificationPressed = createAction(TYPES.SET_PRESSED);
export const setTriggerNotifications = createAction(TYPES.SET_EXTERNAL_TRIGGER_NOTIFICATION);

export const initAstrologistNotifications = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const enabled = state?.remoteConfig?.remoteConfigParams?.astrologistNotificationConfig?.enabled;
    const daysOfRandomMessages = state?.remoteConfig?.remoteConfigParams?.astrologistNotificationConfig?.daysOfRandomMessages;
    const categories = state?.remoteConfig?.remoteConfigParams?.astrologistNotificationConfig?.categories || [];

    const PAUSE_DAYS_COUNT = 3;

    if (!enabled) {
      return;
    }

    const {
      transits: { astroeventTransit },
      astrologers: {
        notifications: { notification: prevNotification, lastContextId, randomCounter },
      },
    } = state;

    const nextUpdateDate = state.profile.profileData?.userParams?.advisor_notification_next_date || '';

    const title = astroeventTransit.title;
    const duration = astroeventTransit.duration;
    const transit = `'${title}' ${duration}`;

    const diff = getDiffMinutesFromNow(nextUpdateDate);

    if (diff <= 0) {
      return !!prevNotification && dispatch(addNotificationToQueue(NOTIFICATIONS.ASTROLOGER));
    }

    const showNotificationMessageHandler = (notification: AstrologerNotification) => {
      const nextDate = getNextMidnight();
      dispatch(setUserParams({ advisor_notification_next_date: nextDate }));

      dispatch(setNotification(notification));
      dispatch(addNotificationToQueue(NOTIFICATIONS.ASTROLOGER));
      return true;
    };

    const prepareAndShowNotification = ({ notification_id, message_id, astrologer_id, message, category }: AdvisorNotification) => {
      const allAstrologers = getState().astrologers.core.allAstrologers;
      const astrologer = allAstrologers.find(a => +a.astrologer_id === +astrologer_id);

      if (!astrologer) {
        return false;
      }

      return showNotificationMessageHandler({ notification_id, message_id, astrologer, message, category });
    };

    if (categories?.includes?.(ADVISOR_NOTIFICATIONS.CONTEXT)) {
      const contextNotification = await getNotification([ADVISOR_NOTIFICATIONS.CONTEXT], transit);
      if (!_.isEmpty(contextNotification) && contextNotification.notification_id !== lastContextId) {
        dispatch(setLastContextNotificationId(contextNotification.notification_id));
        return prepareAndShowNotification(contextNotification);
      }
    }

    const randomCategories = categories.filter((category: ADVISOR_NOTIFICATIONS) => category !== ADVISOR_NOTIFICATIONS.CONTEXT);

    if (randomCategories.length) {
      const daysFromLastUpdate = getDiffDaysFromNow(nextUpdateDate);
      const generateRandomNotification = async () => {
        const randomNotification = await getNotification(randomCategories, transit);
        dispatch(incRandomCounter());
        return prepareAndShowNotification(randomNotification);
      };

      if (randomCounter < daysOfRandomMessages) {
        return generateRandomNotification();
      } else if (daysFromLastUpdate > PAUSE_DAYS_COUNT) {
        dispatch(resetRandomCounter());
        return generateRandomNotification();
      } else {
        // PAUSE DAYS HERE
      }
    }
  };
};

export const resetAstrologistNotifications = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(removeNotificationFromQueue(NOTIFICATIONS.ASTROLOGER));
    dispatch(setNotification(null));
  };
};

export const addTriggerNotification = (notification: TriggerNotification) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        notifications: { triggerNotifications },
      },
    } = getState();

    if (!triggerNotifications?.length) {
      return dispatch(setTriggerNotifications([notification]));
    }

    const updatedTriggerNotifications = triggerNotifications.reduce((acc, item) => {
      if (item.type === notification.type) {
        return acc;
      }

      return [...acc, notification];
    }, triggerNotifications);

    const highPriorityNotifications = [EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED];

    const sortedNotifications = updatedTriggerNotifications.sort((a, b) => {
      const aPriority = highPriorityNotifications.includes(a.type) ? -1 : 1;
      const bPriority = highPriorityNotifications.includes(b.type) ? -1 : 1;
      return aPriority - bPriority;
    });

    return dispatch(setTriggerNotifications(sortedNotifications));
  };
};

export const removeTriggerNotifications = (notifications: EXTERNAL_TRIGGER_NOTIFICATIONS[]) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        notifications: { triggerNotifications },
      },
    } = getState();

    const formattedNotifications = triggerNotifications.filter(({ type }) => !notifications.includes(type));
    dispatch(setTriggerNotifications(formattedNotifications));
  };
};

export const showInstallMobileNotification = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      remoteConfig: {
        remoteConfigParams: { installMobileNotification },
      },
      profile: {
        profileData: { userParams },
        isUserLoyal,
      },
    } = state;

    const isEnabled = installMobileNotification?.enabled;
    const promocode = installMobileNotification?.promocode || 'rnw_to_app';

    if (!isEnabled || Platform.OS !== 'web' || isStandalone() || !isUserLoyal) {
      dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP, EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP]));
      return false;
    }

    if (userParams?.app_installed) {
      Analytics.track('TopNotification_Show', { type: EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP });
      return dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP }));
    }

    const onShow = (seconds = 0) => {
      Analytics.track('TopNotification_Show', { type: EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP, seconds, promocode });
      dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP, seconds }));
    };

    try {
      const promocodeInfo = await getPromocode(promocode);

      onShow(promocodeInfo?.seconds);
    } catch (error) {
      console.log('ERROR getPromocode', error);
      onShow();
    }
  };
};

export const handleInstallMobileNotification = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { installMobileNotification, appLink },
      },
    } = getState();
    const promocode = installMobileNotification?.promocode;

    dispatch(transferInApp(appLink, promocode, true));
    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP]));
  };
};

export const transferInApp = (appLink: string, promocode?: string, isInstallApp = false) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      navigation: { currentRoute },
    } = getState();

    let pathname = Object.entries(PATH_ROUTES_MAP).find(([_key, value]) => value === currentRoute)?.[0];

    if (BIRTH_CHART_ROUTES.includes(currentRoute)) {
      pathname = PATH_NAMES['birth-chart'];
    }

    if (ADVISORS_ROUTES.includes(currentRoute) && ADVISORS_CHAT !== currentRoute) {
      pathname = PATH_NAMES.advisors;
    }

    const link = await dispatch(generateAppLink(appLink, { promocode, install_app: isInstallApp, pathname }));

    window.open(link, '_blank');
  };
};

export const addPromocodeInPWA = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { installMobileNotification },
      },
      astrologers: {
        notifications: { isPromocodeAddedInPwa },
      },
    } = getState();

    const isEnabled = installMobileNotification?.enabled;
    const promocode = installMobileNotification?.promocode;

    if (!isEnabled || !promocode?.length || !isStandalone() || isPromocodeAddedInPwa) {
      return false;
    }

    await dispatch(handleExternalTrigger(promocode));
    dispatch(setPromocodeAddedInPwa());
  };
};
