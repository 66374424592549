import { APP_NAME } from 'constants/general';

import { Product } from '../interfaces/product';
import { PERIOD_TYPES } from '../constants';
import { PRODUCT_TYPES } from '../constants/product';

const periods: Record<PERIOD_TYPES, string> = {
  P1D: '1 day',
  P1W: '1 week',
  P2W: '2 weeks',
  P3W: '3 weeks',
  P1M: '1 month',
  P2M: '2 months',
  P3M: '3 months',
  P5M: '5 months',
  P6M: '6 months',
  P1Y: '1 year',
  P360D: '360 days',
  P3D: '3 days',
  P7W: '7 weeks',
  P7D: '7 days',
  P14D: '14 days',
  P30D: '30 days',
  P60D: '60 days',
  P90D: '90 days',
  P180D: '180 days',
};

const PRODUCTS: Record<string, string> = {
  numerology_analysis: 'Numerology Analysis',
  palmistry_guide: 'Palmistry Guide',
  astrologer_consultation: 'Advisors Consultation',
  birth_chart: 'Birth Chart Report',
  compatibility: 'Compatibility Report',
  numerology_analysis_palmistry_guide: 'Numerology Analysis + Palmistry Guide',
  compatibility_birth_chart_ultra: 'Compatibility Report + Birth Chart Report',
  astrocartography: 'Astrocartography Report',
  palm_reading: 'Palm Reading',
};

const getOneTimePaymentDesc = (s: string) => {
  let text = PRODUCTS[s];

  if (!text && s.indexOf('astrologer_consultation') >= 0) {
    text = PRODUCTS.astrologer_consultation;
  }

  if (!text) {
    text = s;
  }

  return text;
};

export const createOrderDescription = (product: Product) => {
  if (product.type === PRODUCT_TYPES.ONE_TIME) {
    return `${APP_NAME} ${getOneTimePaymentDesc(product.id)}`;
  }

  // Uncomment this block if we will have subscriptions with trial period
  // if (product.isTrial) {
  //   return `${APP_NAME} ${product.trial_period_days}-days Subscription`;
  // }

  const periodDescription = product.period ? periods[product.period] : 'Unknown Period';
  return `${APP_NAME} ${periodDescription} Subscription`;
};
