import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState } from 'store';
import Analytics from 'analytics';
import { ONBOARDING_SCREENS, ONBOARDING_SUB_IDS } from 'constants/onboarding-types';

import { whereToNavigate, reset, navigate } from '../navigation/actions';
import { requestReviewOnOnboarding } from '../rate-us/actions';

import { selectOnboardingSteps, selectFullOnboardingSteps } from './selectors';
import { TYPES } from './types';

export const setOnboardingProfile = createAction(TYPES.SET_ONBOARDING_PROFILE);
export const setOnboardingStep = createAction(TYPES.SET_ONBOARDING_STEP);
export const setOnboardingCompleted = createAction(TYPES.SET_IS_ONBOARDING_COMPLETED);
export const resetOnboardingData = createAction(TYPES.RESET_ONBOARDING_DATA);

export function initOnboardingNavigation() {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const onboardingSteps = selectOnboardingSteps(getState());
    const firstStep = onboardingSteps[0];

    Analytics.track('init_app_navigation_onboarding');

    if (onboardingSteps?.length && Object.keys(ONBOARDING_SCREENS).includes(firstStep)) {
      Analytics.track('init_app_navigation_onboarding_first_screen', { screen: firstStep });
      dispatch(setOnboardingStep({ step: firstStep }));
      reset(ONBOARDING_SCREENS[firstStep]);
      dispatch(requestReviewOnOnboarding(firstStep));
      return true;
    }

    Analytics.track('init_app_navigation_onboarding_go_next');
    dispatch(goToNextStep());
  };
}

export function goToNextStep() {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const {
      onboarding: { step, isOnboardingCompleted },
    } = state;

    const onboardingSteps = selectOnboardingSteps(state);

    const idxStep = step !== null ? onboardingSteps.indexOf(step) + 1 : 0;
    const currentStep = onboardingSteps[idxStep];

    if (isOnboardingCompleted) {
      return dispatch(whereToNavigate());
    }

    if (!currentStep) {
      dispatch(onboardingCompleted());
      return dispatch(whereToNavigate());
    }

    dispatch(setOnboardingStep({ step: currentStep }));
    dispatch(requestReviewOnOnboarding(currentStep));

    return navigate(ONBOARDING_SCREENS[currentStep]);
  };
}

export function updateCurrentStepAfterPurchase() {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      billing: { purchased },
      onboarding: { step },
    } = state;

    const steps = selectFullOnboardingSteps(state);
    let indexStep = step !== null ? steps.indexOf(step) : 0;
    let currentStep = steps[indexStep];

    if (purchased && indexStep && Object.keys(ONBOARDING_SUB_IDS).includes(currentStep)) {
      --indexStep;
      currentStep = steps[indexStep];
    }

    dispatch(setOnboardingStep({ step: currentStep }));

    if (purchased && indexStep && Object.keys(ONBOARDING_SUB_IDS).includes(currentStep)) {
      dispatch(updateCurrentStepAfterPurchase());
    }
  };
}

export function goToPreviousStep() {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const {
      onboarding: { step },
    } = state;

    const onboardingSteps = selectOnboardingSteps(state);

    const idxStep = step !== null ? onboardingSteps.indexOf(step) - 1 : -1;
    const currentStep = onboardingSteps[idxStep];

    dispatch(setOnboardingStep({ step: currentStep }));

    return navigate(ONBOARDING_SCREENS[currentStep]);
  };
}

export function onboardingCompleted() {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    if (getState().onboarding.isOnboardingCompleted) {
      return;
    }

    Analytics.track('onboarding_flow_success');
    dispatch(setOnboardingCompleted(true));
  };
}
