import React, { FC } from 'react';
import { ActivityIndicator, StyleSheet, Platform, ViewStyle } from 'react-native';

import { BEIGE } from '../constants/colors';

interface Props {
  style?: ViewStyle;
}

const AI: FC<Props> = ({ style }) => {
  return <ActivityIndicator size={Platform.OS === 'ios' ? 'large' : 'small'} style={[styles.root, style]} color={BEIGE} />;
};

export default AI;

const styles = StyleSheet.create({
  root: {
    flexGrow: 100,
  },
});
