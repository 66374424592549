import React, { FC, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import Analytics from 'analytics';
import * as MODALS from 'constants/modals';
import { useAppDispatch, useAppSelector } from 'store';
import { closeCurrentModal } from 'store/modals/actions';
import { setTimePaused } from 'store/astrologers/time/actions';

import ContentComponent from '../components/success-content';

interface Props {}

const AuthSuccessModal: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const isLoaded = useAppSelector(state => state.app.isLoaded);
  const isShown = useAppSelector(state => state.modals.activeModal === MODALS.AUTH_SUCCESS);
  const email = useAppSelector(state => state.modals.params?.email);
  const reload = useAppSelector(state => !!state.modals.params?.reload);
  const isVisible = isShown && isLoaded;

  const handleClosePress = () => dispatch(closeCurrentModal());

  useEffect(() => {
    dispatch(setTimePaused(isVisible));

    if (isVisible) {
      Analytics.track('auth_success_modal_shown');
    }
  }, [isVisible]);

  return (
    <Modal style={styles.container} isVisible={isVisible} animationOut="fadeOut" animationOutTiming={200}>
      <ContentComponent email={email} reload={reload} onClose={handleClosePress} />
    </Modal>
  );
};

export default AuthSuccessModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
});
