import { Platform } from 'react-native';

import { BROWSERS_NAMES, ALLOWED_BROWSERS, WEB_APP_TYPES, ALLOWED_BROWSER_TYPES, BROWSERS_TYPES } from 'constants/pwa';

export const isIos = (): boolean => {
  if (Platform.OS !== 'web') {
    return false;
  }
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent) || (window.navigator?.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1);
};

export const isMacOs = (): boolean => {
  if (Platform.OS !== 'web') {
    return false;
  }
  // @ts-ignore
  const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
  const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  return macosPlatforms.indexOf(platform) !== -1;
};

export const isAppleDevice = (): boolean => {
  return isIos() || isMacOs();
};

export const isAndroid = (): boolean => {
  if (Platform.OS !== 'web') {
    return false;
  }
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /android/.test(userAgent);
};

export const isSamsungBrowser = (): boolean => {
  if (Platform.OS !== 'web') {
    return false;
  }
  const userAgent = window.navigator.userAgent;
  return /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/.test(userAgent);
};

export const isSafariBrowser = (name: BROWSERS_NAMES | any): boolean => {
  return [BROWSERS_NAMES.IOS_MOBILE_SAFARI, BROWSERS_NAMES.IOS_SAFARI].includes(name);
};

export const isSafariDesktop = () => {
  return 'safari' in window;
};

export const isAllowedBrowser = (name: BROWSERS_NAMES | null): boolean => {
  return Platform.OS === 'web' && !!name && ALLOWED_BROWSERS.includes(name);
};

export const isAllowedBrowserByType = (browserType: BROWSERS_TYPES): boolean => {
  return Platform.OS === 'web' && !!browserType && ALLOWED_BROWSER_TYPES.includes(browserType);
};

/**
 * @returns
 * true if was opened as PWA
 * the samsung browser always return true
 */
export const isStandalone = (): boolean => {
  if (Platform.OS !== 'web') {
    return false;
  }
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return true; // Trusted web app
  } else if (('standalone' in window.navigator && window.navigator.standalone) || isStandalone) {
    return true;
  }
  return false;
};

export const isAstroline = process.env.REACT_APP_TITLE?.toLowerCase() === WEB_APP_TYPES.ASTROLINE;

export const getPlatform = () => {
  if (Platform.OS === 'web') {
    return isStandalone() ? 'pwa' : 'rnw';
  }
  return Platform.OS;
};
