import { createSelector } from 'reselect';

import { ONBOARDING_IDS, ONBOARDING_SCREENS, ONBOARDING_SUB_IDS } from 'constants/onboarding-types';
import { RootState } from 'store';

const getOnboardingFlow = (state: RootState) => state.remoteConfig.remoteConfigParams.onboardingFlow;
const getBillingPurchased = (state: RootState) => state.billing.purchased;

export const selectOnboardingSteps = createSelector([getOnboardingFlow, getBillingPurchased], (onboardingFlow, purchased) => {
  let resultFlow = onboardingFlow ? [...onboardingFlow.map(i => i.toUpperCase())] : [];

  resultFlow = resultFlow.filter((x: string) => Object.keys(ONBOARDING_SCREENS).includes(x));

  if (purchased) {
    resultFlow = resultFlow.filter((x: string) => !Object.keys(ONBOARDING_SUB_IDS).includes(x));
  }

  return resultFlow;
});

export const selectFullOnboardingSteps = createSelector([getOnboardingFlow], onboardingFlow => {
  let resultFlow = onboardingFlow ? [...onboardingFlow.map(i => i.toUpperCase())] : [];
  resultFlow = resultFlow.filter((x: string) => Object.keys(ONBOARDING_SCREENS).includes(x));
  return resultFlow;
});

const AVOID_DOTS_SCREENS = [ONBOARDING_IDS.WELCOME, ONBOARDING_IDS.PROCESSING];

export const selectOnboardingStepsWithDots = createSelector([getOnboardingFlow], onboardingFlow =>
  onboardingFlow
    ? [...onboardingFlow.map(i => i.toUpperCase())]
        .filter((x: string) => Object.keys(ONBOARDING_SCREENS).includes(x))
        .filter((x: string) => !AVOID_DOTS_SCREENS.includes(x))
        .filter((x: string) => !Object.keys(ONBOARDING_SUB_IDS).includes(x))
    : [],
);
