import { Platform } from 'react-native';

import Analytics from 'analytics';
import { ChatPlatforms, HistoryItem, HistoryItemMetadata } from 'api/astrology-chat/constants';
import { isStandalone } from 'utils/pwa';

const isTheMessageWithSelectedType = (messages: HistoryItem[], type: HistoryItemMetadata['type']) => {
  const isExist = messages.find(mess => {
    if (mess?.metadata) {
      let metadata = mess.metadata;

      if (typeof metadata === 'string') {
        metadata = JSON.parse(metadata);
      }
      const meta = metadata as HistoryItemMetadata;
      return meta?.type === type;
    }

    return undefined;
  });

  return !!isExist;
};

export const handleEventsForSpecificMessages = (messages: HistoryItem[]) => {
  const isTarotCard = isTheMessageWithSelectedType(messages, 'tarotCard');
  const isVoiceMessage = isTheMessageWithSelectedType(messages, 'voiceMessage');

  if (isTarotCard) {
    Analytics.track('Astrologist_Tarot_Card_Received');
  }

  if (isVoiceMessage) {
    Analytics.track('Voice_Message_Received');
  }
};

export const platform = Platform.select<ChatPlatforms>({
  ios: 'ios',
  android: 'android',
  web: isStandalone() ? 'pwa' : 'rnw',
});

export const getNumberMessagesUserAndAstrologist = (messages: HistoryItem[]): { userMessagesCount: number; astrologistMessagesCount: number } => {
  let userCount = 0;
  let astrologistCount = 0;

  for (let i = 0; i < messages.length; i++) {
    const type = messages[i]?.type;

    if (type === 'question') {
      userCount++;
    }
    if (type === 'answer') {
      astrologistCount++;
    }
  }

  return { userMessagesCount: userCount, astrologistMessagesCount: astrologistCount };
};
