import { createAction } from 'redux-actions';

import * as ChatApi from 'api/astrology-chat';
import { AccrueTimeRequestParams, Balances } from 'api/astrology-chat/constants';
import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';

import { rewardForRenewHandler, showAutoRefillModal } from '../modals/actions';

import { TYPES } from './types';

export const setTimerTooltipVisibility = createAction(TYPES.SET_TOOLTIP_VISIBILITY);
export const setTimerTooltipClosed = createAction(TYPES.SET_TOOLTIP_CLOSED);
export const setAvailableSeconds = createAction(TYPES.SET_AVAILABLE_SECONDS);
export const setTimePaused = createAction(TYPES.SET_TIME_PAUSED);
export const setAwaitingNewTimeBalance = createAction(TYPES.SET_AWAITING_NEW_TIME_BALANCE);
export const setLastTransactionDate = createAction(TYPES.SET_LAST_TRANSACTION_DATE);
export const setChatTimer = createAction(TYPES.SET_CHAT_TIMER);
export const setTimeUp = createAction(TYPES.SET_TIME_UP);
const setBalancesAction = createAction(TYPES.SET_BALANCES);
const setIncreasingChatTimer = createAction(TYPES.SET_INCREASING_CHAT_TIMER);

export const initChatTimer = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { enableIncreasingTimer },
      },
      astrologers: {
        time: { seconds },
        core: { isUnlimitedMode },
      },
    } = getState();

    const isIncreasingChatTimer = isUnlimitedMode || enableIncreasingTimer;

    dispatch(setIncreasingChatTimer(isIncreasingChatTimer));

    dispatch(setChatTimer(isIncreasingChatTimer ? 0 : seconds));

    if (isUnlimitedMode) {
      dispatch(setTimeUp(false));
    }
  };
};

export const chargeTime = (seconds: number, chatId: number) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        core: { isUnlimitedMode },
      },
    } = getState();

    const timeBalance = await ChatApi.chargeTime(!isUnlimitedMode ? seconds : 0, chatId);

    if (isUnlimitedMode) {
      return false;
    }

    dispatch(setBalances(timeBalance));
    return timeBalance;
  };
};

export const dailyRewards = (seconds: number) => {
  return async (dispatch: AppDispatch) => {
    const timeBalance = await ChatApi.dailyRewards(seconds);
    dispatch(setBalances(timeBalance));
  };
};

export const setBalances = ({ balance = 0, freeBalance = 0 }: Balances) => {
  return (dispatch: AppDispatch) => {
    dispatch(setAvailableSeconds(balance));
    dispatch(setTimeUp(balance === 0));
    dispatch(
      setBalancesAction({
        balance,
        freeBalance,
      }),
    );
  };
};

export const accrueFreeTime = (params: AccrueTimeRequestParams) => {
  return async (dispatch: AppDispatch) => {
    await ChatApi.accrueTime(params);
    dispatch(getAvailableTime());
  };
};

export const setAstrologistFreeTime = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { purchased },
      remoteConfig: {
        remoteConfigParams: {
          astrologistConfig: { freeMinutes, freeMinutesForFreeUsers },
        },
      },
    } = getState();
    const timeBalance = await ChatApi.getTimeBalance();

    if (!timeBalance.isRewardedForUnpaidUser && !purchased) {
      await dispatch(accrueFreeTime({ time: freeMinutesForFreeUsers * 60, type: 'unpaid', increaseFreeBalance: true }));
    }

    if (!timeBalance.isRewardedForFirstUse && purchased) {
      await dispatch(accrueFreeTime({ time: freeMinutes * 60, type: 'first', increaseFreeBalance: true }));
    }
  };
};

export const handleTimeUp = () => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { currentAstrologerId, currentChatId },
      },
      profile: {
        profileData: { device_id: idfm, id: userId },
      },
    } = getState();

    Analytics.track('Astrologist_Time_Up', { userId, idfm, chatId: currentChatId, astrologerId: currentAstrologerId });
  };
};

export const countChatTime = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        core: { isUnlimitedMode },
        chat: { currentChatId },
        time: { seconds, chatTimer, isIncreasingChatTimer },
      },
    } = getState();

    const currentTime = seconds - 1;

    dispatch(setChatTimer(isIncreasingChatTimer ? chatTimer + 1 : currentTime));

    if (currentTime <= 0 && !isUnlimitedMode) {
      const timeBalance = await ChatApi.getTimeBalance();
      await dispatch(chargeTime(timeBalance.balance, currentChatId));

      ChatApi.endChatLog(currentChatId, {
        reason: 'time_up',
        balance: seconds,
      });

      return dispatch(showAutoRefillModal());
    }

    if (!isUnlimitedMode) {
      dispatch(setAvailableSeconds(currentTime));
    }
  };
};

export const getAvailableTime = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { accessToken },
      astrologers: {
        core: { isUnlimitedMode },
      },
    } = getState();
    if (isUnlimitedMode) {
      return undefined;
    }

    if (!accessToken) {
      return undefined;
    }

    const timeBalance = await ChatApi.getTimeBalance();

    dispatch(rewardForRenewHandler(!timeBalance?.isRewardedForRenew));
    dispatch(setAvailableSeconds(timeBalance.balance));
    dispatch(setTimeUp(timeBalance.balance === 0));
    dispatch(setBalances(timeBalance));
    return timeBalance;
  };
};
