import React from 'react';
import { StyleSheet } from 'react-native';

import AI from './activity-indicator';

const ButtonSpinner = () => {
  return <AI style={styles.spinner} />;
};

export default ButtonSpinner;

const styles = StyleSheet.create({
  spinner: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});
