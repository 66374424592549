import React, { FC } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Analytics from 'analytics';
import * as TRIGGERS from 'constants/monetization-triggers';
import { AppDispatch, RootState } from 'store';
import { navigateWithMonetization, setBackgroundPlacementVisibility } from 'store/unlock-content/actions';

interface Props {}

const BackgroundPlacement: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isBackgroundPlacementVisible = useSelector((state: RootState) => state.unlockContent.isBackgroundPlacementVisible);

  const handlePress = () => {
    Analytics.track('background_placement_click');

    dispatch(
      navigateWithMonetization({
        trigger: TRIGGERS.APP_FOREGROUND,
      }),
    );
    dispatch(setBackgroundPlacementVisibility(false));
  };

  if (!isBackgroundPlacementVisible) {
    return null;
  }

  return <Pressable style={[StyleSheet.absoluteFill, styles.container]} onPress={handlePress} />;
};

const styles = StyleSheet.create({
  container: {
    zIndex: 999,
  },
});

export default BackgroundPlacement;
