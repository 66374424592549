import { Platform } from 'react-native';
import { ofetch } from 'ofetch';
import DeviceInfo from 'react-native-device-info';

import { IS_DEV_BUILD } from 'constants/general';

export let DOMAIN: any = IS_DEV_BUILD ? 'https://astrology-stage.astroline.app/api/v1' : 'https://astrology.astroline.app/api/v1';
export const APP_URL = IS_DEV_BUILD ? 'https://astrology-stage.astroline.app' : 'https://astrology.astroline.app';

const isLogEnabled = false;

if (Platform.OS === 'web') {
  DOMAIN = process.env.REACT_APP_API_BASE_URL;
}

const fetch = ofetch.create({
  baseURL: DOMAIN,
  onRequest({ request, options }) {
    if (isLogEnabled) {
      console.log(`[API ${options.method} REQUEST]: `, request);
    }
  },
  onResponse({ response }) {
    if (isLogEnabled) {
      console.log('[API RESPONSE]: ', response);
    }
  },
  onRequestError({ request, options, error }) {
    if (isLogEnabled) {
      console.error(`[API ${options.method} REQUEST ERROR]: `, request, error);
    }
  },
  onResponseError({ response, error }) {
    if (isLogEnabled) {
      console.error('[API RESPONSE ERROR]: ', response, error);
    }
  },
});

const api = {
  token: '',

  setToken(newToken: string) {
    this.token = newToken;
  },

  fetch(url: string, options: any, pureBodyData = false) {
    const body = options.data ? (pureBodyData ? options.data : JSON.stringify(options.data)) : undefined;
    return fetch(url, {
      ...options,
      retry: 2,
      body,
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${this.token}` || undefined,
        'Content-Type': 'application/json; charset=UTF-8',
        ...options.headers,
        'X-platform': Platform.OS,
        'X-version': DeviceInfo.getVersion(),
      },
    }).catch(err => {
      throw err;
    });
  },
};

export default api;
