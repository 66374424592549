import React, { FC, memo } from 'react';
import { StyleSheet, TouchableOpacity, StyleProp, ImageStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

interface Props {
  value: number;
  icon: FC<SvgProps>;
  style?: StyleProp<ImageStyle> | undefined;
  onPress: (value: number) => void;
}

const Star: FC<Props> = ({ onPress, icon, value, style }) => {
  const Icon = icon;

  const handlePress = () => onPress(value);

  return (
    <TouchableOpacity style={[styles.button, style]} onPress={handlePress}>
      <Icon width={49} height={48} />
    </TouchableOpacity>
  );
};

export default memo(Star);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
  },
});
