import React, { FC } from 'react';
import { StyleSheet, Text, StyleProp, ViewStyle, TouchableOpacity } from 'react-native';

import * as COLORS from 'constants/colors';

interface Props {
  title: string;
  isBoldTitle?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
}

const Button: FC<Props> = ({ title, isBoldTitle, style, onPress }) => (
  <TouchableOpacity style={[styles.button, style]} onPress={onPress}>
    <Text style={[styles.title, isBoldTitle && styles.titleBold]}>{title}</Text>
  </TouchableOpacity>
);

export default Button;

const styles = StyleSheet.create({
  button: {
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 17,
    lineHeight: 22,
    letterSpacing: -0.41,
    fontWeight: '400',
    color: COLORS.NATIVE_IOS_MODAL_COLOR,
  },
  titleBold: {
    fontWeight: '600',
  },
});
