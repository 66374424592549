import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { t } from 'localization';

import ErrorModal from '../components/error-modal';
import Analytics from '../analytics';

export default class DisconnectModal extends PureComponent {
  static propTypes = {
    onPress: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
  };

  componentDidMount() {
    Analytics.track('Disconnect_Retry_Open');
  }

  handlePress = () => {
    const { onPress } = this.props;
    this.setState({ isLoading: true }, () => {
      onPress().finally(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  render() {
    return (
      <ErrorModal
        onPress={this.handlePress}
        isLoading={this.state.isLoading}
        buttonTitle={t('ERROR_MODAL.RETRY')}
        title={t('ERROR_MODAL.DISCONNECT')}
        subtitle={t('ERROR_MODAL.SOME_PROBLEMS')}
        style={{ paddingVertical: 0, paddingHorizontal: 0 }}
      />
    );
  }
}
