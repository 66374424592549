import { createAction } from 'redux-actions';
import { getMessaging as getFirebaseMessaging, getToken as getMessagingToken, isSupported } from 'firebase/messaging';

import Analytics from 'analytics';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { setFCMToken } from 'api/notifications';
import { TYPES } from 'store/notification/types';
import { isStandalone } from 'utils/pwa';

const setFcmTokenHash = createAction(TYPES.SET_FCM_TOKEN_HASH);
const setPermissionRequested = createAction(TYPES.SET_PERMISSION_REQUESTED);
const setWebNotificationRequestOverlayVisibility = createAction(TYPES.SET_WEB_NOTIFICATION_REQUEST_OVERLAY_VISIBILITY);

export const setNotificationTime = () => {
  return async (_dispatch: AppDispatch) => {};
};

export const initNotifications = () => {
  return async (_dispatch: AppDispatch) => {};
};

export const isNotificationAvailableToShow = () => {
  return async (_dispatch: AppDispatch) => {};
};

// TODO Этот метод закоменчен в методе notificationsRequest. Оставил на случай, если нужно будет воскресить
// const initSetAppBadgeWorker = async () => {
//   try {
//     if (!navigator?.serviceWorker) {
//       return false;
//     }

//     const registration = await navigator.serviceWorker.ready;

//     await registration?.periodicSync?.unregister('set-app-badge');

//     const bgSync = await navigator.permissions?.query({ name: 'periodic-background-sync' });
//     const notifications = await navigator.permissions?.query({ name: 'notifications' });

//     if (bgSync.state !== 'granted' || notifications.state !== 'granted') {
//       return false;
//     }

//     const minInterval = 12 * 60 * 60 * 1000;
//     await registration.periodicSync.register('set-app-badge', { minInterval });
//   } catch (e) {
//     console.log('> serviceWorker error', e);
//   }
// };

// for rnw possible to request notification only after user interaction with app (click event), then we show overlay for this
export const notificationsRequest = (isInitApp = false): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { notificationsOnStart, notificationsOnDashboardSession },
      },
    } = getState();

    const currentPermission = window?.Notification?.permission;

    if (currentPermission === 'granted') {
      dispatch(updateFcmToken());
      // initSetAppBadgeWorker();
    }

    if (!isStandalone()) {
      return false;
    }

    if (['denied', 'granted'].includes(currentPermission)) {
      return false;
    }

    const sessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;

    if ((isInitApp && notificationsOnStart) || (!isInitApp && sessionNumber === notificationsOnDashboardSession)) {
      Analytics.track('notification_show_web_request_overlay', { onStart: isInitApp });
      dispatch(setWebNotificationRequestOverlayVisibility(true));
    }
  };
};

export const requestPermissions = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      notification: { isPermissionRequested },
    } = getState();

    try {
      const permission = await window?.Notification?.requestPermission();
      const granted = permission === 'granted';

      if (!isPermissionRequested) {
        dispatch(setPermissionRequested());
        Analytics.trackEvent('notification', 'requested', { permission });
      }
      if (granted) {
        dispatch(updateFcmToken());
        // initSetAppBadgeWorker(); // TODO хз нужен ли сейчас?
      }
      dispatch(setWebNotificationRequestOverlayVisibility(false));
      return granted;
    } catch (e) {
      Analytics.track('notification_request_error', { error: String(e) });
      console.log('> Request notification ERROR', e);
    }
  };
};

export const request = (isInitApp = false): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { notificationsOnStart, notificationsOnDashboardSession },
      },
    } = getState();

    try {
      if (typeof navigator?.setAppBadge === 'function') {
        navigator.setAppBadge(0);
      }

      const currentPermission = window?.Notification?.permission;

      if (currentPermission === 'granted') {
        dispatch(updateFcmToken());
        // initSetAppBadgeWorker();
      }

      if (['denied', 'granted'].includes(currentPermission)) {
        return false;
      }

      const sessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;

      if (isInitApp && !notificationsOnStart) {
        return false;
      }

      if (!isInitApp && sessionNumber !== notificationsOnDashboardSession) {
        return false;
      }

      const permission = await window?.Notification?.requestPermission?.();

      if (permission === 'granted') {
        dispatch(updateFcmToken());
        // initSetAppBadgeWorker();
      }

      Analytics.track('notification_web_requested', { onStart: isInitApp, permission });
    } catch (error) {
      Analytics.track('notification_web_error', { error: String(error) });
      console.log('> Request notification ERROR', error);
    }
  };
};

export const getToken = async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (!isSupportedBrowser) {
      return '';
    }

    const messaging = getFirebaseMessaging();

    // A temporary solution for registering/replacing a service worker, since an error occurs on the firebase side when the app is launched for the first time
    // https://github.com/firebase/firebase-js-sdk/issues/7693#issuecomment-1849813958
    navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase-cloud-messaging-push-scope' });

    const token = await getMessagingToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_TOKEN });
    return token;
  } catch (e) {
    console.log('> request notification permissions error', e);
  }
};

export const updateFcmToken = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { remoteNotificationsEnabled },
      },
      auth: { id },
    } = getState();

    // just dont send token
    if (!remoteNotificationsEnabled) {
      return false;
    }

    try {
      const fcmToken = await getToken();
      const hash = `${id}:${fcmToken}`;
      // console.log('>>>>>>>>> hash', hash);
      if (id && fcmToken) {
        setFCMToken(fcmToken);
        dispatch(setFcmTokenHash(hash));
      }
    } catch (e) {
      console.log('> update fcmToken error', e);
    }
  };
};
