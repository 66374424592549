import { createSelector } from 'reselect';

import { RootState } from 'store';
import { UserReport } from 'store/upsails/types';
import { REPORTS_PRODUCT_CODES } from 'constants/upsale';
import { getDiffSecondsFromNow } from 'utils/date';

const getUserReports = (state: RootState) => state.upsails.userReports;
const getAvailableReports = (state: RootState) => state.upsails.availableReports;

export const filterProcessingReports = (reports: UserReport[]): UserReport[] => {
  return reports.filter(report => {
    const remainingSeconds = report.readyDate ? getDiffSecondsFromNow(report.readyDate) : 0;
    return remainingSeconds > 0;
  });
};

export const getAvailableReportsToBuy = createSelector(
  [getUserReports, getAvailableReports],
  (userReports, availableReports): UserReport['productCode'][] => {
    return availableReports.filter(report => !userReports.some(userReport => userReport.productCode === report));
  },
);

export const getNotViewedReports = createSelector([getUserReports], (userReports): UserReport[] => {
  return userReports.filter(report => !report.isViewed);
});

export const getProcessingReports = createSelector([getUserReports], (userReports): UserReport[] => {
  return filterProcessingReports(userReports);
});

export const getPalmReport = createSelector([getUserReports], (reports): UserReport | undefined => {
  return reports.find(report => report.productCode === REPORTS_PRODUCT_CODES.PALM_READING);
});

export const getReportByProductCode = createSelector(
  [getUserReports, (_state, productCode: REPORTS_PRODUCT_CODES) => productCode],
  (reports, productCode): UserReport | undefined => {
    return reports.find(report => report.productCode === productCode);
  },
);
