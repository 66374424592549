import React, { forwardRef, memo, useImperativeHandle, useState, useCallback, useEffect } from 'react';
import { StyleSheet, Platform } from 'react-native';

import Analytics from 'analytics';
import { PAYMENT_EVENTS_CATEGORIES } from 'analytics/const';
import { useAppDispatch, useAppSelector } from 'store';
import { payByOneClick, subscribeByOneClick, fetchPaymentMethod, verifyWebSubscriptions } from 'store/billing/general-actions';
import { subscribe, restore, setPending, trackWebInAppPurchase } from 'store/billing/actions';
import { checkOneClickAvailable } from 'store/billing/selectors';
import useSolidMetadata from 'hooks/use-solid-metadata';
import PaymentsWebview from 'modules/payments/components/webview';
import PaymentsMethodsModal from 'modules/payments/components/modals/methods';
import PaymentsErrorModal from 'modules/payments/components/modals/error';
import PaymentsSuccessModal from 'modules/payments/components/modals/success';
import { ERROR_CODES } from 'modules/payments/constants/error';
import LoaderOverlay from 'components/loader/overlay';
import { PAYMENT_METHODS, ONECLICK_METHOD, REACTIVATE_METHOD } from 'modules/payments/constants';
import { PRODUCT_TYPES } from 'modules/payments/constants/product';
import { AllPaymentMethods } from 'modules/payments/interfaces';
import * as COLORS from 'constants/colors';

import { createOrderDescription } from './utils/create-order-description';
import { Product } from './interfaces/product';

export interface PaymentsRef {
  isPaymentsModalVisible: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onReactivated: (data: any) => void;
  onRestore: () => void;
}

interface Props {
  product: Product;
  productTitle: string;
  productTrigger: string;
  eventCategory: PAYMENT_EVENTS_CATEGORIES;
  withSuccessModal: boolean;
  metadata?: {
    [key: string]: any;
  };
  isReactivate?: boolean;
  eventParams?: any;
  onSuccessModalClose?: () => void;
  onErrorModalClose?: () => void;
  onSuccess?: (data: any) => void;
  onError?: () => void;
}

const Payments = forwardRef<PaymentsRef, Props>(
  (
    {
      product,
      productTitle,
      productTrigger,
      isReactivate,
      eventCategory,
      withSuccessModal,
      eventParams = {},
      metadata = {},
      onSuccessModalClose,
      onErrorModalClose,
      onSuccess,
      onError,
    },
    ref,
  ) => {
    const [isPaymentsModalVisible, setPaymentsModalVisible] = useState(false);
    const [paymentsMethod, setPaymentsMethods] = useState<PAYMENT_METHODS | null>(null);
    const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
    const [isErrorModalVisible, setErrorModalVisible] = useState(false);
    const [errorCode, setErrorCode] = useState('');
    const [isWebViewLoaded, setWebViewLoaded] = useState(Platform.OS === 'web' ? false : true);
    const dispatch = useAppDispatch();
    const solidMetadata = useSolidMetadata();
    const isPending = useAppSelector(state => state.billing.pending);
    const idfm = useAppSelector(state => state.profile.profileData.device_id);
    const availablePaymentMethods = useAppSelector(state => state.remoteConfig.remoteConfigParams.availablePaymentMethods);
    const isOneClickAvailable = useAppSelector(checkOneClickAvailable);

    useEffect(() => {
      if (Platform.OS === 'web') {
        setWebViewLoaded(false);
      }
    }, [product]);

    useImperativeHandle(ref, () => ({
      isPaymentsModalVisible,
      onSubmit: handleSubmit,
      onClose: handleClose,
      onReactivated: handleReactivated,
      onRestore: handleRestore,
    }));

    const handlePaymentsMethodButtonPress = useCallback(
      (method: PAYMENT_METHODS) => {
        Analytics.trackPaymentEvent(eventCategory, 'Method_Button_Click', {
          ...eventParams,
          method,
          productCode: product.id,
          productType: product?.type,
        });

        dispatch(setPending(true));
        if (method !== PAYMENT_METHODS.APPLE_PAY && method !== PAYMENT_METHODS.GOOGLE_PAY) {
          setPaymentsMethods(method);
        }
        setPaymentsModalVisible(false);
      },
      [product, eventParams, eventCategory],
    );

    const handleSuccess = useCallback(
      (method: AllPaymentMethods, data: any) => {
        if (product.type === PRODUCT_TYPES.ONE_TIME) {
          trackWebInAppPurchase();
        }

        Analytics.trackPaymentEvent(eventCategory, 'Success', {
          ...eventParams,
          method,
          productCode: product.id,
          productType: product?.type,
          currency: product?.currency,
          amount: product?.price,
        });

        setErrorModalVisible(false);
        setPaymentsModalVisible(false);
        if (withSuccessModal) {
          setSuccessModalVisible(true);
        }

        if (typeof onSuccess === 'function') {
          onSuccess(data);
        }
        dispatch(setPending(false));
      },
      [product, eventParams, eventCategory, onSuccess],
    );

    const handleError = useCallback(
      (method: AllPaymentMethods, error: any) => {
        const code = error?.code?.toString()?.replaceAll('.', '');

        Analytics.trackPaymentEvent(eventCategory, 'Error', {
          ...eventParams,
          method,
          productCode: product.id,
          productType: product?.type,
          currency: product?.currency,
          amount: product?.price,
          message: error?.message,
          code: error?.code,
        });

        if (code === ERROR_CODES.INSUFFICENT_FUNDS) {
          setErrorCode(error?.code);
        }

        setPaymentsModalVisible(false);

        dispatch(setPending(false));
        setErrorModalVisible(true);

        if (typeof onError === 'function') {
          onError();
        }
      },
      [product, eventCategory, eventParams, onError],
    );

    const handleSubmit = () => {
      Analytics.trackPaymentEvent(eventCategory, 'Submit', {
        ...eventParams,
        productCode: product.id,
        productType: product?.type,
        currency: product?.currency,
        amount: product?.price,
        isOneClickAvailable: !!isOneClickAvailable,
      });

      if (product?.type === PRODUCT_TYPES.SUBSCRIPTION) {
        if (Platform.OS !== 'web') {
          dispatch(subscribe(product.id));
          return;
        } else if (isReactivate) {
          return;
        }
      }

      if (!isOneClickAvailable) {
        if (!isWebViewLoaded) {
          dispatch(setPending(true));
        }

        setPaymentsModalVisible(true);
      } else {
        // ONECLICK HERE
        dispatch(setPending(true));

        const data = {
          amount: product.price,
          currency: product.currency,
          productCode: product.id,
          metadata,
          description: createOrderDescription(product),
          productTrigger,
          solidMetadata,
        };

        const _onSuccess = (responseData: any) => {
          handleSuccess(ONECLICK_METHOD, responseData);
        };

        const _onError = (error: any) => {
          handleError(ONECLICK_METHOD, error);
          dispatch(setPending(false));
        };

        if (product?.type === PRODUCT_TYPES.SUBSCRIPTION) {
          dispatch(
            subscribeByOneClick({
              data,
              onSuccess: _onSuccess,
              onError: _onError,
            }),
          );
        } else {
          dispatch(
            payByOneClick({
              data,
              onSuccess: _onSuccess,
              onError: _onError,
            }),
          );
        }
      }
    };

    const handleClose = () => {
      if (isPaymentsModalVisible) {
        setPaymentsModalVisible(false);
        setPaymentsMethods(null);
      }
    };

    const handleRestore = async () => {
      Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION, 'restore_start', eventParams);
      const successful = await dispatch(restore());
      if (successful) {
        Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION, 'Restore', eventParams);

        if (withSuccessModal) {
          setSuccessModalVisible(true);
        } else {
          handleSuccessModalClose();
        }
      }
    };

    const handleReactivated = (data: any) => {
      handleSuccess(REACTIVATE_METHOD, data);
    };

    const handErrorButtonPress = () => {
      setErrorModalVisible(false);
      setErrorCode('');

      setTimeout(() => {
        setPaymentsModalVisible(true);
      }, 700);
    };

    const handleWebViewLoaded = () => {
      setWebViewLoaded(true);
    };

    const handlePaymentMethodDismiss = () => {
      setPaymentsModalVisible(false);
      dispatch(setPending(false));
    };

    const handlePaymentsWebViewDismiss = () => {
      setPaymentsMethods(null);
      dispatch(setPending(false));
    };

    const handleErrorModalClose = () => {
      setErrorModalVisible(false);
      setErrorCode('');

      if (typeof onErrorModalClose === 'function') {
        onErrorModalClose();
      }
    };

    const handleSuccessModalClose = () => {
      setSuccessModalVisible(false);

      dispatch(verifyWebSubscriptions());
      dispatch(fetchPaymentMethod());

      if (typeof onSuccessModalClose === 'function') {
        onSuccessModalClose();
      }
    };

    return (
      <>
        <LoaderOverlay visible={isPending} style={[styles.overlayStyles, isPaymentsModalVisible && isWebViewLoaded && { zIndex: 1 }]} />

        <PaymentsMethodsModal
          isVisible={isPaymentsModalVisible && isWebViewLoaded}
          idfm={idfm}
          metadata={metadata}
          title={productTitle}
          product={product}
          productTrigger={productTrigger}
          availableMethods={availablePaymentMethods}
          eventParams={eventParams}
          solidMetadata={solidMetadata}
          onButtonPress={handlePaymentsMethodButtonPress}
          onSuccess={handleSuccess}
          onError={handleError}
          onDismiss={handlePaymentMethodDismiss}
          onLoaded={handleWebViewLoaded}
        />

        {paymentsMethod === PAYMENT_METHODS.CARD || paymentsMethod === PAYMENT_METHODS.PAYPAL ? (
          <PaymentsWebview
            idfm={idfm}
            title={productTitle}
            metadata={metadata}
            method={paymentsMethod}
            product={product}
            productTrigger={productTrigger}
            solidMetadata={solidMetadata}
            onError={handleError}
            onSuccess={handleSuccess}
            onDismiss={handlePaymentsWebViewDismiss}
            onLoaded={handleWebViewLoaded}
          />
        ) : null}

        {withSuccessModal && (
          <PaymentsSuccessModal
            isVisible={isSuccessModalVisible}
            isReactivate={isReactivate && product?.type === PRODUCT_TYPES.SUBSCRIPTION}
            type={product?.type}
            onClose={handleSuccessModalClose}
          />
        )}

        <PaymentsErrorModal
          idfm={idfm}
          metadata={metadata}
          product={product}
          productTrigger={productTrigger}
          isVisible={isErrorModalVisible}
          withPaypal={availablePaymentMethods?.includes(PAYMENT_METHODS.PAYPAL) && Platform.OS === 'web'}
          statusCode={errorCode}
          onSuccess={handleSuccess}
          onClose={handleErrorModalClose}
          onButtonPress={handErrorButtonPress}
        />
      </>
    );
  },
);

export default memo(Payments);

const styles = StyleSheet.create({
  overlayStyles: {
    backgroundColor: COLORS.GRAY_DARK_TRANSPERENT,
  },
});
