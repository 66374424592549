import moment from 'moment';

import { Biorhythms } from 'api/biorhythms/interfaces';

import { BIORHYTHM_TYPES, PERIODS_DAYS } from '../../constants/charts';
import { PERIODS_IDS } from '../../constants/periods';

import { AverageBiorhythms, Periods, BiorhythmsTypesDataWWithKeys, BiorhythmsTypesData, SortedBiorhythms, ChartsData } from './types';

const UPPER_NUMERICAL_BORDER = 100;
const SEGMENT_LENGTH = 200;
const MILLISECONDS_PER_DAY = 86400000;

const PERIODS = [PERIODS_IDS.YESTERDAY, PERIODS_IDS.TODAY, PERIODS_IDS.TOMORROW] as const;

export const getChartsData = (date: string, counts: number): ChartsData => {
  const firstDate = moment(date, 'YYYY-MM-DD').toDate();
  const now = new Date();
  const diffDays = Math.floor(Math.abs((firstDate.getTime() - now.getTime()) / MILLISECONDS_PER_DAY));

  const days = Array(counts)
    .fill(-Math.floor(counts / 2))
    .map((element, index) => index + element);

  const chartsData = BIORHYTHM_TYPES.reduce((acc, periodName) => {
    const period = PERIODS_DAYS[`${periodName}`];

    return {
      ...acc,
      [periodName]: days.map(day => Math.round(Math.sin(((2 * Math.PI) / period) * (((diffDays + day) % period) + 1)) * 100) || 0),
    };
  }, {} as ChartsData);

  return chartsData;
};

const getAverageBiorhythms = (date: string): AverageBiorhythms => {
  const chartsData = getChartsData(date, 5);
  const bufferPeriod = 'BUFFER';
  const periods = [bufferPeriod, ...PERIODS] as Periods[];
  const periodsIndexes = [0, 1, 2, 3];

  const result = periods.reduce((acc, period, index) => {
    const prevPeriod = index > 0 ? acc[periods[index - 1]] : undefined;
    let average = 0;
    const biorhythmTypesData = BIORHYTHM_TYPES.reduce<BiorhythmsTypesDataWWithKeys>((biorhythmAcc, type) => {
      const periodIndex = periodsIndexes[index];
      const data = chartsData[`${type}`];

      return {
        ...biorhythmAcc,
        [type]: {
          average: data[periodIndex],
          isMore: average > data[periodIndex - 1],
          isEqual: average === data[periodIndex - 1],
          type,
        },
      };
    }, {} as BiorhythmsTypesDataWWithKeys);

    const averageCount = Object.values(biorhythmTypesData).reduce((averageAcc, item) => averageAcc + item.average, 0);
    average = Math.round(averageCount / BIORHYTHM_TYPES.length);

    return {
      ...acc,
      [period]: {
        ...biorhythmTypesData,
        average,
        isEqual: prevPeriod ? prevPeriod.average === average : false,
        isMore: prevPeriod ? prevPeriod.average < average : false,
        period,
      },
    };
  }, {} as AverageBiorhythms);

  delete result[bufferPeriod];

  return result;
};

const getSortedBiorhythms = (biorhythms: Biorhythms): SortedBiorhythms => {
  const sortedBiorhythms = biorhythms.reduce((acc, item) => {
    const key = item.category.toUpperCase();
    return {
      ...acc,
      [key]: [...(acc?.[key] || []), item],
    };
  }, {} as SortedBiorhythms);

  return sortedBiorhythms;
};

export const getFullBiorhythms = (date: string, biorhythms = [] as Biorhythms): AverageBiorhythms => {
  const averageBiorhythm = getAverageBiorhythms(date);
  const sortedBiorhythms = getSortedBiorhythms(biorhythms);

  const fullBiorhythms = Object.entries(averageBiorhythm).reduce((acc, item) => {
    const [day, biorhythm] = item;

    const categories = Object.values(biorhythm).filter(biorhythmItem => biorhythmItem instanceof Object) as BiorhythmsTypesData[];

    const texts = categories.reduce((categoriesAcc, category) => {
      const categoryName = category.type;
      const average = category.average;
      const data = sortedBiorhythms[categoryName];
      const categoryLength = average === UPPER_NUMERICAL_BORDER ? data.length - 1 : data.length;
      const numericalSpan = Math.floor(((UPPER_NUMERICAL_BORDER + average) * categoryLength) / SEGMENT_LENGTH);

      return {
        ...categoriesAcc,
        [categoryName]: {
          ...category,
          categoryText: data[numericalSpan],
        },
      };
    }, {});

    return {
      ...acc,
      [day]: {
        ...biorhythm,
        ...texts,
      },
    };
  }, {} as AverageBiorhythms);

  return fullBiorhythms;
};
