import { useEffect, useRef } from 'react';

import Analytics from 'analytics';
import { useAppDispatch, useAppSelector } from 'store';
import { getAvailableTime, setAwaitingNewTimeBalance, setLastTransactionDate } from 'store/astrologers/time/actions';
import { getAwaitingNewTimeBalanceStatus } from 'store/astrologers/selectors';

import usePrevious from './use-previous';

const useChatTimeBalance = () => {
  const dispatch = useAppDispatch();

  const isAwaitingNewTimeBalance = useAppSelector(getAwaitingNewTimeBalanceStatus);
  const freeBalance = useAppSelector(state => state.astrologers.time.balances?.freeBalance);
  const balance = useAppSelector(state => state.astrologers.time.balances?.balance);
  const lastTransactionDate = useAppSelector(state => state.astrologers.time.lastTransactionDate);

  const prevFreeBalance = usePrevious(freeBalance);
  const counter = useRef(0);

  useEffect(() => {
    let interval: any;

    if (isAwaitingNewTimeBalance) {
      interval = setInterval(async () => {
        if (counter.current >= 10) {
          Analytics.track('GetBalance_Delay', { lastTransactionDate });
          counter.current = 0;
          dispatch(setAwaitingNewTimeBalance(false));
          return clearInterval(interval);
        }

        const time = await dispatch(getAvailableTime());
        if (time?.lastTransactionDate !== lastTransactionDate) {
          Analytics.track('GetBalance_Success');
          counter.current = 0;
          dispatch(setLastTransactionDate(time?.lastTransactionDate));
          dispatch(setAwaitingNewTimeBalance(false));
          clearInterval(interval);
        } else {
          counter.current += 1;
        }
      }, 2000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, isAwaitingNewTimeBalance, lastTransactionDate]);

  useEffect(() => {
    if (prevFreeBalance && prevFreeBalance > 0 && freeBalance === 0) {
      Analytics.track('Advisor_Chat_FreeBalance_Expired', { balance, freeBalance });
    }
  }, [freeBalance, prevFreeBalance, balance]);
};

export default useChatTimeBalance;
